import types from "../types";
const initialState = {
    getSchoolDropdown: [],
    getSchool: [],
    addSchools: [],
    viewSchool: {},
    ViewScoolGrades: {},
    ViewSchoolInstrument: {},
    viewSchoolTeachers: [],
    viewSchoolGradeClassType: [],
    viewSchoolPrograms: [],
    viewProgramInstruments: {},
};
const SchoolReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.FETCH_SCHOOLS_DROPDOWN:
            return {
                ...state,
                getSchoolDropdown: action.payload,
            };
        case types.FETCH_SCHOOLS:
            return {
                ...state,
                getSchool: action.payload,
            };
        case types.VIEW_SCHOOL:
            return {
                ...state,
                viewSchool: action.payload,
            };
        case types.SCHOOL_GRADES:
            return {
                ...state,
                ViewScoolGrades: action.payload,
            };
        case types.SCHOOL_INSTRUMENT:
            return {
                ...state,
                ViewSchoolInstrument: action.payload,
            };
        case types.VIEW_SCHOOL_TEACHERS:
            return { ...state, viewSchoolTeachers: action.payload };
        case types.VIEW_SCHOOL_GRADE_CLASS_TYPE:
            return { ...state, viewSchoolGradeClassType: action.payload };
        case types.CLASS_TYPE_PROGRAMS:
            return { ...state, viewSchoolPrograms: action.payload };
        case types.SCHOOL_PROGRAM_INSTRUMENTS:
            return { ...state, viewProgramInstruments: action.payload };

        default:
            return { ...state };
    }
};

export default SchoolReducer;
