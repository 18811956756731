import React from "react";

export default function Footer() {
    return (
        <>
            <div className="p-3 bg-dark">
                <div className="text-center">
                    <p className="text-main text-sm open-sans font-normal">© 2023 Fundamental music. All rights reserved</p>
                </div>
            </div>
        </>
    );
}
