import React, { useEffect, useState } from "react";
import Logo from "../../assets/logo/logo.png";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import ButtonComponent from "../Fields/Button/CustomButton";
import { jsPDF } from "jspdf";
import PaidImg from "../../assets/icons/paid-img.png";
import DeleteIcon from "../../assets/icons/delete.png";
import { useDispatch, useSelector } from "react-redux";
import { PaymentInvoiceAction, deleteInvoiceAction, getInvoiceDetails } from "../../redux/actions/invoicesAction";
import { confirmDelete } from "../../utils/commonFunctions";
import DeclinedImg from "../../assets/icons/declined.png";
import VoidImg from "../../assets/icons/void-img.png";
import { Dialog } from "primereact/dialog";
import AddAndEditCard from "../../portals/admin/student/FamilyTab/AddAndEditCard";
import { Button } from "primereact/button";
import { getParentCard } from "../../redux/actions/familyAction";
import Delete from "../../assets/icons/delete.png";
import AddNewCard from "../../portals/admin/student/FamilyTab/AddNewCard";
import RadioButtonComponent from "../Fields/Radio";

export const InvoiceGenerate = ({ data, setInvoiceView }) => {
    const dispatch = useDispatch();
    const invoiceDetails = useSelector((state) => state?.invoices?.invoiceDetails);

    const DeleteInvoice = (position) => {
        confirmDelete(
            () => {
                dispatch(deleteInvoiceAction(data?.invoiceId, data?.id, setInvoiceView));
            },
            "Do you want to delete this Invoice?",
            position
        );
    };

    useEffect(() => {
        dispatch(getParentCard(data?.parentId));
    }, []);

    const cardDetails = useSelector((state) => state?.family?.parentCard);

    useEffect(() => {
        if (cardDetails?.card_details?.length > 0) {
            setCardId(cardDetails?.default_card_id);
        }
    }, [cardDetails]);
    const [cardId, setCardId] = useState(cardDetails?.[0]?.id);
    useEffect(() => {
        if (data.parentId && data.invoiceId) {
            dispatch(getInvoiceDetails(data.parentId, data.invoiceId, data.type, getPDF));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    const getPDF = () => {
        let pdfContainer = document.querySelector("#pdfData");
        let doc = new jsPDF("p", "", "a4");
        doc.html(pdfContainer, {
            callback: function (doc) {
                doc.save(`invoice.pdf`);
            },
            margin: 10,
            width: 190,
            windowWidth: 800,
            autoPaging: "text",
        });
    };
    const [makePayment, setMakePayment] = useState(false);
    const [openCard, setOpenCard] = useState(null);

    const MakePaymentInvoice = () => {
        dispatch(PaymentInvoiceAction(data?.parentId, data?.invoiceId, data?.type, getPDF, cardId, setMakePayment));
    };
    const RadioTemp = (rowData) => {
        return (
            <>
                <RadioButtonComponent
                    name="card_id"
                    value={rowData?.id}
                    checked={cardId === rowData?.id}
                    onChange={(e) => setCardId(e.value)}
                />
            </>
        );
    };
    return (
        <div
            className="p-3 pdf-container relative"
            style={{ fontFamily: "sans-serif", position: "relative", padding: "3rem 1rem !important" }}
        >
            {invoiceDetails?.status === "paid" || invoiceDetails?.status === "Paid" ? (
                <div
                    className="flex justify-content-center align-items-center "
                    style={{ position: "absolute", zIndex: 999999, width: "100%", height: "100%", opacity: "0.3" }}
                >
                    <img src={PaidImg} alt="" style={{ marginRight: "50px" }} />
                </div>
            ) : invoiceDetails?.status === "declined" ? (
                <div
                    className="flex justify-content-center align-items-center "
                    style={{ position: "absolute", zIndex: 999999, width: "100%", height: "100%", opacity: "0.1" }}
                >
                    <img src={DeclinedImg} alt="" style={{ marginRight: "50px" }} width="600px" />
                </div>
            ) : invoiceDetails?.status === "void" ? (
                <div
                    className="flex justify-content-center align-items-center "
                    style={{ position: "absolute", zIndex: 999999, width: "100%", height: "100%", opacity: "0.1" }}
                >
                    <img src={VoidImg} alt="" style={{ marginRight: "50px" }} width="600px" />
                </div>
            ) : (
                ""
            )}
            <div className="grid justify-content-between mb-2">
                <div className="md:col-4">
                    <img src={Logo} alt="" className="w-full" />
                </div>
                <div className="md:col-5 text-right text-green-700">
                    <h2 className=" text-4xl font-bold">UPCOMING LESSONS OR EVENTS</h2>
                    <p>
                        <span className="font-bold">Date:</span> <span className="ml-2">{invoiceDetails?.date}</span>
                    </p>
                    <p>
                        <span className="font-bold">Invoice #:</span> <span className="ml-2">{invoiceDetails?.invoiceId}</span>
                    </p>
                </div>
            </div>
            <div className="flex justify-content-between mb-4">
                <div className="w-3">
                    <h4 className="font-bold" style={{ fontFamily: "sans-serif" }}>
                        Fundamental Music Instruction
                    </h4>
                    <p style={{ fontFamily: "sans-serif" }}>Fundamental Music Instruction</p>
                </div>
                <div className="w-3">
                    <h4 className="font-bold">Bill To:</h4>
                    <p className="">{invoiceDetails?.billedTo}</p>
                    {/* <p className="font-bold">Address:</p> */}
                    <i>
                        <p>
                            ({invoiceDetails?.address?.address}, {invoiceDetails?.address?.city},
                        </p>
                        <p>
                            {invoiceDetails?.address?.state}, {invoiceDetails?.address?.zipCode})
                        </p>
                    </i>
                </div>
                <div className="w-3 ">
                    <div className="mb-3">
                        <p className="flex justify-content-between">
                            <span>Previous Balance:</span>
                            <span>${invoiceDetails?.previousBalance}</span>
                        </p>
                        <p className="flex justify-content-between">
                            <span>Payments:</span>
                            <span>${invoiceDetails?.payment}</span>
                        </p>
                        <p className="flex justify-content-between">
                            <span>New Charges:</span>
                            <span>${invoiceDetails?.newCharges}</span>
                        </p>
                    </div>
                    <h3 className="text-right text-green-700 font-semibold">Total Due: ${invoiceDetails?.total}</h3>
                </div>
            </div>
            <DataTable value={invoiceDetails?.data} className="invoice-table mb-3">
                <Column field="date" header="Date" style={{ fontFamily: "sans-serif !important" }}></Column>
                <Column field="description" header="Description" style={{ fontFamily: "sans-serif !important" }}></Column>
                <Column field="charges" header="Charges" style={{ fontFamily: "sans-serif" }}></Column>
                <Column field="payment" header="Payments" style={{ fontFamily: "sans-serif" }}></Column>
            </DataTable>
            <div className="grid m-0">
                <div className="md:col-7 bg-gray-light">
                    <p>Thank you for being a part of our Fundamental Music Instruction Family!</p>
                </div>
                <div className="md:col-5 text-center align-item-center">
                    <h3 className=" text-green-700 font-semibold">Total Due: ${invoiceDetails?.total}</h3>
                    {(invoiceDetails?.status === "pending" || invoiceDetails?.status === "declined") && (
                        <div className="flex">
                            <ButtonComponent
                                label={`${invoiceDetails?.status === "declined" ? "Click Here to Repay" : "Click Here to Pay"}`}
                                className="drk-green-bg text-white border-round-3xl border-none relative invoice-btn-index"
                                onClick={() => setMakePayment(true)}
                                // onClick={MakePaymentInvoice}
                                // onClick={() => history.push("/invoice-payments")}
                            />
                            <ButtonComponent
                                label="Delete"
                                icon={DeleteIcon}
                                className="drk-red-bg text-white border-round-3xl ml-3 border-none relative invoice-btn-index"
                                onClick={DeleteInvoice}
                                // onClick={() => history.push("/invoice-payments")}
                            />
                        </div>
                    )}
                </div>
                <Dialog
                    header="Manage Payment Methods"
                    visible={makePayment}
                    // style={{ width: "50vw" }}
                    onHide={() => setMakePayment(false)}
                >
                    <DataTable
                        className="mb-2"
                        tableStyle={{ minWidth: "50rem" }}
                        value={cardDetails?.card_details?.length && cardDetails?.card_details}
                    >
                        <Column field="cardType" header="Type"></Column>
                        <Column field="number" header="Account Number"></Column>
                        <Column body={RadioTemp} header="Action"></Column>
                    </DataTable>
                    <Button className="bg-light-main text-sm font-semibold" onClick={() => setOpenCard(data?.parentId)}>
                        <span className="ml-2">Add New Card</span>
                    </Button>
                    <ButtonComponent
                        className="bg-light-main text-sm font-semibold mb-2 m-auto block "
                        onClick={MakePaymentInvoice}
                        disable={cardId ? false : true}
                    >
                        <span className="ml-2">Pay Now</span>
                    </ButtonComponent>
                    <Dialog
                        header="Add New Card"
                        className="assign-student border-round-lg overflow-hidden "
                        visible={openCard}
                        style={{ width: "48vw" }}
                        draggable={false}
                        resizable={false}
                        onHide={() => setOpenCard(null)}
                    >
                        <AddNewCard openCard={openCard} setOpenCard={setOpenCard} />
                    </Dialog>
                </Dialog>
            </div>
            <div className="drk-green-bg text-white pdf-footer p-3 justify-content-between flex align-items-center mb-3">
                <p>Fundamental Music Instruction </p>
                <p className="text-sm">Upcoming Lessons or Events</p>
            </div>
        </div>
    );
};
