import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { sendResetPasswordApi } from "../redux/actions/loginAction";

const StepThree = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  var base64 = require("base-64");

  const params = useParams();
  const { encodedEmail } = params;
  const history = useHistory();
  const dispatch = useDispatch();
  const email = base64.decode(encodedEmail);

  const [resetPassword, setResetPassword] = useState({
    email: email,
    new_password: "",
    confirm_password: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setResetPassword({ ...resetPassword, [name]: value });
    setErr({ ...err, [name]: "" });
  };
  const [err, setErr] = useState({});
  const validateForm = () => {
    let errors = {};
    if (!resetPassword?.new_password) {
      errors.new_password = "New password is required ";
    }
    if (!resetPassword?.confirm_password) {
      errors.confirm_password = "Confirm Password  is required ";
    } else if (resetPassword.new_password !== resetPassword?.confirm_password) {
      errors.confirm_password = "Password and confirm password does not match";
    }

    setErr(errors);
    if (Object.keys(errors).length === 0) {
      return true;
    } else {
      return false;
    }
  };

  const handleResetPassword = (e) => {
    e.preventDefault();
    const form = validateForm();
    if (form) {
      dispatch(
        sendResetPasswordApi(
          resetPassword?.email,
          resetPassword?.new_password,
          history
        )
      );
    }
  };

  return {
    resetPassword,
    handleChange,
    handleResetPassword,
    err,
  };
};

export default StepThree;
