import types from "../types";
const initialState = {
  getInstruments: [],
  viewInstrument: {},
  addInstruments: [
    {
      name: "",
      purchase_amount: null,
      monthly_installment: null,
      number_of_installment: null,
      deposit_amount: null,
    },
  ],
};
const InstrumentReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_INSTRUMENTS:
      return {
        ...state,
        getInstruments: action.payload,
      };
    case types.ADD_INSTRUMENTS:
      return {
        ...state,
        addInstruments: action.payload,
      };
    case types.GET_ONE_INSTRUMENT:
      return {
        ...state,
        viewInstrument: action.payload,
      };

    default:
      return { ...state };
  }
};

export default InstrumentReducer;
