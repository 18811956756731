import React from "react";
import { Card } from "primereact/card";

const CustomCard = ({ title, children }) => {
  return (
    <div className="shadow-2 border-round-lg mt-2  p-4 mb-5">
      <h5 className="mb-3 font-semibold">{title}</h5>
      {children}
    </div>
  );
};

export default CustomCard;
