import types from "../types";
const initialState = {
    addEvent: {},
    getEvents: [],
    ViewEvent: {},
    filter: { students: "", teachers: "", schools: "", category: "" },
    calendarDate: new Date(),
};
const EventReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.ADD_EVENTS:
            return {
                ...state,
                addEvent: action.payload,
            };
        case types.FETCH_EVENTS:
            return {
                ...state,
                getEvents: action.payload,
            };
        case types.VIEW_EVENT:
            return {
                ...state,
                ViewEvent: action.payload,
            };
        case types.GET_CALENDAR_FILTER:
            return { ...state, filter: action.payload };
        case types.CHANGE_CALENDAR_DATE:
            return { ...state, calendarDate: action.payload };

        default:
            return { ...state };
    }
};

export default EventReducer;
