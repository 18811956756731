let types = {
    SHOW_LOADER: "SHOW_LOADER",
    HIDE_LOADER: "HIDE_LOADER",
    ADMIN_DETAIL: "ADMIN_DETAIL",
    FETCH_INSTRUMENTS: "FETCH_INSTRUMENTS",
    ADD_INSTRUMENTS: "ADD_INSTRUMENTS",
    GET_ONE_INSTRUMENT: "GET_ONE_INSTRUMENT",
    FETCH_PURCHASE_PLAN: "FETCH_PURCHASE_PLAN",
    ADD_PURCHASE_PLAN: "ADD_PURCHASE_PLAN",
    GET_ONE_PURCHASE_PLAN: "GET_ONE_PURCHASE_PLAN",
    FORM_BUILDER: "FORM_BUILDER",
    FETCH_FORM: "FETCH_FORM",
    VIEW_FORM: "VIEW_FORM",
    FETCH_LESSONS_PROGRAM: "FETCH_LESSONS_PROGRAM",
    GET_LESSON_STUDENTS: "GET_LESSON_STUDENTS",
    ADD_SCHOOL_LESSONS: "ADD_SCHOOL_LESSONS",
    VIEW_SCHOOL_LESSON: "VIEW_SCHOOL_LESSON",
    ADD_ONLINE_SCHOOL_LESSON: "ADD_ONLINE_SCHOOL_LESSON",
    VIEW_ONLINE_LESSON: "VIEW_ONLINE_LESSON",
    FETCH_SCHOOLS: "FETCH_SCHOOLS",
    FETCH_SCHOOLS_DROPDOWN: "FETCH_SCHOOLS_DROPDOWN",
    FETCH_BOOKS: "FETCH_BOOKS",
    VIEW_BOOK: "VIEW_BOOK",
    ADD_BOOKS: "ADD_BOOKS",
    VIEW_SCHOOL: "VIEW_SCHOOL",
    GET_PROFILE: "GET_PROFILE",
    GET_STUDENTS: "GET_STUDENTS",
    VIEW_STUDENT: "VIEW_STUDENT",
    GET_PRIVELEGES: "GET_PRIVELEGES",
    FETCH_TEACHERS: "FETCH_TEACHERS",
    GET_FILTER_LESSON: "GET_FILTER_LESSON",
    VIEW_TEACHER: "VIEW_TEACHER",
    DELETE_ATTACHMENT: "DELETE_ATTACHMENT",
    GET_INVOICES: "GET_INVOICES",
    GET_INVOICES_TRANSACTIONS: "GET_INVOICES_TRANSACTIONS",
    DELETE_SCHOOL_ATTACHMENT: "DELETE_SCHOOL_ATTACHMENT",
    CHANGE_TEACHER_PASSWORD: "CHANGE_TEACHER_PASSWORD",
    ADD_CATEGORIES: "ADD_CATEGORIES",
    FETCH_CATEGORIES: "FETCH_CATEGORIES",
    VIEW_CATEGORY: "VIEW_CATEGORY",
    ADD_EVENTS: "ADD_EVENTS",
    FETCH_EVENTS: "FETCH_EVENTS",
    VIEW_EVENT: "VIEW_EVENT",
    VIEW_ATTENDENCE: "VIEW_ATTENDENCE",
    VIEW_PREFRENCES: "VIEW_PREFRENCES",
    VIEW_TEACHER_EVENTS: "VIEW_TEACHER_EVENTS",
    VIEW_TEACHER_ATTANDENCE: "VIEW_TEACHER_ATTANDENCE",
    TEACHER_STUDENTS: "TEACHER_STUDENTS",
    TEACHER_CATEGORIES: "TEACHER_CATEGORIES",
    TEACHER_STUDENT_VIEW: "TEACHER_STUDENT_VIEW",
    SCHOOL_GRADES: "SCHOOL_GRADES",
    SCHOOL_INSTRUMENT: "SCHOOL_INSTRUMENT",
    ADD_STUDENT_DATA: "ADD_STUDENT_DATA",
    ADD_STUDENT_PARENT_DATA: "ADD_STUDENT_PARENT_DATA",
    FETCH_FAMILIES: "FETCH_FAMILIES",
    PARENT_CARD: "PARENT_CARD",
    FETCH_FAMILIES_DROPDOWN: "FETCH_FAMILIES_DROPDOWN",
    ONLINE_LESSON_GRADE: "ONLINE_LESSON_GRADE",
    DELETE_STUDENT_ATTACHMENT: "DELETE_STUDENT_ATTACHMENT",
    STUDENT_TEACHERS: "STUDENT_TEACHERS",
    STUDENT_All_TEACHERS_DROP: "STUDENT_All_TEACHERS_DROP",
    STUDENT_TEACHER_VIEW: "STUDENT_TEACHER_VIEW",
    DELETE_ASSIGN_TEACHER: "DELETE_ASSIGN_TEACHER",
    STUDENT_ATTENDENCE: "STUDENT_ATTENDENCE",
    STUDENT_MESSAGE_HISTORY: "STUDENT_MESSAGE_HISTORY",
    STUDENT_EMAIL_PARENT: "STUDENT_EMAIL_PARENT",
    CHANGE_TAB_INDEX: "CHANGE_TAB_INDEX",
    GET_TEACHER_AVAILIBILITIES: "GET_TEACHER_AVAILIBILITIES",
    TEACHER_AVAILABILITY_VIEW: "TEACHER_AVAILABILITY_VIEW",
    VIEW_TEACHER_SCHOOLS: "VIEW_TEACHER_SCHOOLS",
    FETCH_TEACHER_EVENTS: "FETCH_TEACHER_EVENTS",
    GET_CALENDAR_FILTER: "GET_CALENDAR_FILTER",
    CHANGE_CALENDAR_DATE: "CHANGE_CALENDAR_DATE",
    VIEW_SCHOOL_TEACHERS: "VIEW_SCHOOL_TEACHERS",
    GET_COMPENSATION: "GET_COMPENSATION",
    COMPENSATION_PAYMENT_VIEW: "COMPENSATION_PAYMENT_VIEW",
    GET_LESSONS_SCHOOL_TYPE: "GET_LESSONS_SCHOOL_TYPE",
    VIEW_SCHOOL_GRADE_CLASS_TYPE: "VIEW_SCHOOL_GRADE_CLASS_TYPE",
    CLASS_TYPE_PROGRAMS: "CLASS_TYPE_PROGRAMS",
    SCHOOL_PROGRAM_INSTRUMENTS: "SCHOOL_PROGRAM_INSTRUMENTS",
    FETCH_NEWS: "FETCH_NEWS",
    GET_INVOICE: "GET_INVOICE",
    EMAIL_DROPDOWN: "EMAIL_DROPDOWN",
    GET_MESSAGE_HISTORY: "GET_MESSAGE_HISTORY",
    GET_DASHBOARD_LIST: "GET_DASHBOARD_LIST",
    FETCH_TEACHERS_DROPDOWN: "FETCH_TEACHERS_DROPDOWN",
    GET_OVERDUE_ATTENDANCE: "GET_OVERDUE_ATTENDANCE",
    GET_PAST_NOTES: "GET_PAST_NOTES",
    SET_ALL_UNRECORDED: "SET_ALL_UNRECORDED",
    VIEW_TRANSACTION: "VIEW_TRANSACTION",
    VIEW_STUDENT_NOTES: "VIEW_STUDENT_NOTES",
    GET_NOTIFICATIONS: "GET_NOTIFICATIONS",
    VIEW_AUTO_INVOICING: "VIEW_AUTO_INVOICING",
    SET_INVOICE_DETAILS: "SET_INVOICE_DETAILS",
    GET_EMAIL_TEMPLATE: "GET_EMAIL_TEMPLATE",
    VIEW_TEMPLATE: "VIEW_TEMPLATE",
    VIEW_STUDENT_STATUS: "VIEW_STUDENT_STATUS",
    VIEW_EMAIL: "VIEW_EMAIL",
    GET_INVOICE_EMAIL: "GET_INVOICE_EMAIL",
};
export default types;
