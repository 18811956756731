import types from "../types";
const initialState = {
    getEmails: [],
    getMessageHistory: [],
    emailView: {},
    getInvoiceEmails: {},
};
const EmailReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.EMAIL_DROPDOWN:
            return { ...state, getEmails: action.payload };
        case types.GET_MESSAGE_HISTORY:
            return { ...state, getMessageHistory: action.payload };
        case types.VIEW_EMAIL:
            return { ...state, emailView: action.payload };
        case types.GET_INVOICE_EMAIL:
            return { ...state, getInvoiceEmails: action.payload };
        default:
            return { ...state };
    }
};

export default EmailReducer;
