import { multipartApi, api } from "../../services/api";
import Constants from "../../services/constant";

export const uploadFiles = async (files, type) => {
    const promises = files.map(async (item) => {
        if (item.path) {
            return item;
        } else {
            const formData = new FormData();
            files?.forEach((item, i) => {
                formData.append(`file[${i}]`, item);
            });
            // formData.append("file", item);
            formData.append("type", type);
            const res = await multipartApi("post", Constants.END_POINT.UPLOAD_FILES, formData);
            if (res.success && res.data) {
                return res.data[0];
            }
        }
    });

    const filesResult = await Promise.all(promises);

    if (filesResult?.length === 1) {
        return filesResult.toString();
    } else {
        return filesResult;
    }
};

export const uploadImages = async (images, type) => {
    const promises = images?.map(async (item) => {
        if (typeof item === "string") {
            return item;
        } else {
            const formData = new FormData();
            formData.append("file[0]", item);
            formData.append("type", type);

            const res = await multipartApi("post", Constants.END_POINT.UPLOAD_FILES, formData);
            if (res.success && res.data) {
                return res.data[0];
            }
        }
    });

    const urls = await Promise.all(promises);
    return urls;
};
export const uploadEditorImage = async (image, type) => {
    const formData = new FormData();
    formData.append("file[0]", image);
    formData.append("type", "instruments");
    const res = await multipartApi("post", Constants.END_POINT.UPLOAD_FILES, formData);
    if (res.success && res.data) {
        return Constants.IMAGE_BASE_URL + res.data[0];
    } else {
        return "";
    }
};
