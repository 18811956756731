import types from "../types";
const initialState = {
    getNotifications: [],

};
const settingsReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_NOTIFICATIONS:
            return { ...state, getNotifications: action.payload };
        default:
            return { ...state };
    }
};

export default settingsReducer;
