import types from "../types";
const initialState = {
  viewAttandence: {},
};
const AttendenceReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.VIEW_ATTENDENCE:
      return {
        ...state,
        viewAttandence: action.payload,
      };

    default:
      return { ...state };
  }
};

export default AttendenceReducer;
