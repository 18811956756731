import types from "../types";

const intitalState = {
  loginDetail: {
    email: "",
    token: "",
    rememberMe: "false",
  },
  getProfile: {},
};

const loginReducer = (state = intitalState, action) => {
  switch (action.type) {
    case "USER_DETAIL":
      return {
        ...state,
        loginDetail: action.payload,
      };
    case types.GET_PROFILE:
      return {
        ...state,
        getProfile: action.payload,
      };
    default:
      return { ...state };
  }
};

export default loginReducer;
