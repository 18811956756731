import Constants from "../../services/constant";
import { api } from "../../services/api";
import types from "../types";
import { showToast } from "../../utils/showToast";

export const getNotificationAction = (setLoading, type) => async (dispatch) => {
    if (setLoading) {
        setLoading(true);
    }

    const res = type
        ? await api("get", Constants.END_POINT.GET_NOTIFICATIONS + "/" + type, {}, {})
        : await api("get", Constants.END_POINT.GET_NOTIFICATIONS, {}, {});
    if (res.success) {
        dispatch({
            type: types.GET_NOTIFICATIONS,
            payload: res.data,
        });
    }

    if (setLoading) {
        setLoading(false);
    }
};

export const ToggleNotificationAction = (ids, setSelectedRow, IsRead) => async (dispatch) => {
    const payload = { notification_ids: ids?.map((v) => v.id), is_read: IsRead };
    const res = await api("post", Constants.END_POINT.TOGGLE_NOTIFICATIONS, payload);
    if (res.success) {
        showToast([res.message], "success");
        setSelectedRow(null);
        dispatch(getNotificationAction(null));
    } else {
        showToast([res.message], "error");
    }
};

export const deleteSelectedNotificationAction = (ids, setSelectedRow) => async (dispatch) => {
    const payload = { notification_ids: ids?.map((v) => v.id) };
    const res = await api("post", Constants.END_POINT.DELETE_NOTIFICATIONS, payload);
    if (res.success) {
        showToast([res.message], "success");
        setSelectedRow(null);
        dispatch(getNotificationAction(null));
    } else {
        showToast([res.message], "error");
    }
};
