export const loadStateFn = (params) => localStorage.getItem(params);

export const saveStateFn = (key, value) => localStorage.setItem(key, value);

export const clearStateFn = () => localStorage.clear();

export const removeStateFn = (key) => localStorage.removeItem(key);

export const sessionGetFn = (params) => sessionStorage.getItem(params);

export const sessionSaveFn = (key, value) => sessionStorage.setItem(key, value);

export const clearSessionFn = () => sessionStorage.clear();

export const removeSessionFn = (key) => sessionStorage.removeItem(key);
