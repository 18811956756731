import types from "../types";
const initialState = {
  getBooks: [],
  ViewBook: {},
  addBooks: [],
};
const BooksReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_BOOKS:
      return {
        ...state,
        getBooks: action.payload,
      };
    case types.ADD_BOOKS:
      return {
        ...state,
        addBooks: action.payload,
      };
    case types.VIEW_BOOK:
      return {
        ...state,
        ViewBook: action.payload,
      };

    default:
      return { ...state };
  }
};

export default BooksReducer;
