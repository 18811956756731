import types from "../types";
const initialState = {
    getFamilies: [],
    familyDropdown: [],
    parentCard:[]
};
const FamilyReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.FETCH_FAMILIES:
            return {
                ...state,
                getFamilies: action.payload,
            };
        case types.FETCH_FAMILIES_DROPDOWN:
            return {
                ...state,
                familyDropdown: action.payload,
            };
        case types.PARENT_CARD:
            return {
                ...state,
                parentCard: action.payload,
            };
        default:
            return { ...state };
    }
};

export default FamilyReducer;
