import { combineReducers } from "redux";
import loaderReducer from "./loaderReducer";
import InstrumentReducer from "./InstrumentReducer";
import purchasePlanReducer from "./purchasePlanReducer";
import formbuilderReducer from "./formbuilderReducer";
import lessonsProgramReducer from "./lessonsProgramReducer";
import SchoolReducer from "./SchoolReducer";
import BooksReducer from "./booksReducer";
import loginReducer from "./loginReducer";
import StudentsReducer from "./studentReducer";
import TeacherReducer from "./teacherReducer";
import invoicesReducer from "./invoicesReducer";
import CategoryReducer from "./categoryReducer";
import EventReducer from "./eventReducer";
import AttendenceReducer from "./attendenceReducer";
import EmailReducer from "./emailReducer";
import FamilyReducer from "./familyReducer";
import newsReducer from "./newsReducer";
import DashboardReducer from "./dashboardReducer";
import settingsReducer from "./settingsReducer";
import EmailTemplateReducer from "./emailTemplateReducer";

export default combineReducers({
    loader: loaderReducer,
    instruments: InstrumentReducer,
    purchasePlan: purchasePlanReducer,
    form: formbuilderReducer,
    lessonsProgram: lessonsProgramReducer,
    schools: SchoolReducer,
    books: BooksReducer,
    login: loginReducer,
    student: StudentsReducer,
    teacher: TeacherReducer,
    invoices: invoicesReducer,
    category: CategoryReducer,
    event: EventReducer,
    attendence: AttendenceReducer,
    email: EmailReducer,
    family: FamilyReducer,
    news: newsReducer,
    dashboard: DashboardReducer,
    settings: settingsReducer,
    emailTemplate: EmailTemplateReducer,
});
