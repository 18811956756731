import types from "../types";
const initialState = {
    getStudents: [],
    viewStudent: {},
    addStudent: {},
    addParent: [],
    viewStudentTeacher: [],
    viewAssignTeacher: {},
    getStudentAttendence: [],
    getMessageHistory: [],
    getParents: [],
    studentTabIndex: 0,
    viewNotes: "",
    viewType: "",
};
const StudentsReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_STUDENTS:
            return {
                ...state,
                getStudents: action.payload,
            };
        case types.VIEW_STUDENT:
            return {
                ...state,
                viewStudent: action.payload,
            };
        case types.ADD_STUDENT_DATA:
            return {
                ...state,
                addStudent: action.payload,
            };
        case types.ADD_STUDENT_PARENT_DATA:
            return {
                ...state,
                addParent: action.payload,
            };
        case types.STUDENT_TEACHERS:
            return {
                ...state,
                viewStudentTeacher: action.payload,
            };
        case types.STUDENT_TEACHER_VIEW:
            return { ...state, viewAssignTeacher: action.payload };
        case types.STUDENT_ATTENDENCE:
            return { ...state, getStudentAttendence: action.payload };
        case types.STUDENT_MESSAGE_HISTORY:
            return { ...state, getMessageHistory: action.payload };
        case types.STUDENT_EMAIL_PARENT:
            return { ...state, getParents: action.payload };
        case types.CHANGE_TAB_INDEX:
            return { ...state, studentTabIndex: action.payload };
        case types.VIEW_STUDENT_NOTES:
            return { ...state, viewNotes: action.payload };
        case types.VIEW_STUDENT_STATUS:
            return { ...state, viewType: action.payload };
        default:
            return { ...state };
    }
};

export default StudentsReducer;
