import { lazy } from "react";
const InvoiceEmail = lazy(() => import("../portals/admin/Families&Invoice/InvoiceEmail.js"));
const Dashboard = lazy(() => import("../portals/admin/dashboard"));
const Admin = lazy(() => import("../portals/admin/adminmenu/Admin"));
const NewUpdate = lazy(() => import("../portals/admin/adminmenu/NewUpdate"));
const Teacher = lazy(() => import("../portals/admin/teacher/Teacher"));
const Addteacher = lazy(() => import("../portals/admin/teacher/addteacher"));
const UserDetail = lazy(() => import("../portals/admin/teacher/userDetail"));
const School = lazy(() => import("../portals/admin/school/School"));
const Addschool = lazy(() => import("../portals/admin/school/addschool"));
const Student = lazy(() => import("../portals/admin/student/Student"));
const AddStudent = lazy(() => import("../portals/admin/student/addStudent"));
const OnlineResourcesView = lazy(() => import("../portals/admin/OnlineResources/OnlineResourcesView"));
const OnlineResources = lazy(() => import("../portals/admin/OnlineResources/OnlineResources"));
const ResourcesView = lazy(() => import("../portals/admin/OnlineResources/ResourcesView"));
const Website = lazy(() => import("../portals/admin/website/Website"));
const PageEditor = lazy(() => import("../portals/admin/website/PageEditor"));
const SocialMedia = lazy(() => import("../portals/admin/website/SocialMedia"));
const Instruments = lazy(() => import("../portals/admin/Instruments/Instruments"));
const PurchasePlan = lazy(() => import("../portals/admin/Instruments/PurchasePlan"));
const AddFormEditor = lazy(() => import("../portals/admin/admissionform/admissionform"));
const ManageMusicProgram = lazy(() => import("../portals/admin/MusicProgram/ManageMusicProgram"));
const MyPreferences = lazy(() => import("../portals/admin/settings/MyPreferences"));
const Notification = lazy(() => import("../portals/admin/settings/Notification"));
const FamilyContect = lazy(() => import("../portals/admin/student/familyContect"));
const AutomativeInvoice = lazy(() => import("../portals/admin/student/automativeInvoice"));
const StudentDetail = lazy(() => import("../portals/admin/student/studentDetail"));
const Editschool = lazy(() => import("../portals/admin/school/editSchool"));
const Books = lazy(() => import("../portals/admin/books/Books"));
const BusinessSettings = lazy(() => import("../portals/admin/settings/BusinessSettings"));
const NewCustomTemplate = lazy(() => import("../portals/admin/settings/NewCustomTemplate"));
const Families = lazy(() => import("../portals/admin/Families&Invoice/Families"));
const AccountDetails = lazy(() => import("../portals/admin/Families&Invoice/AccountDetails"));
const Calendar = lazy(() => import("../portals/admin/Calendar/Calendar"));
const StudentAttandance = lazy(() => import("../portals/admin/Calendar/StudentAttandance"));
const Categories = lazy(() => import("../portals/admin/Calendar/Category/Categories.jsx"));
const CreateAndEditEvent = lazy(() => import("../portals/admin/Calendar/CreateAndEditEvent"));
const Revenue = lazy(() => import("../portals/admin/revenue/Revenue"));
const RevenueCategories = lazy(() => import("../portals/admin/revenue/RevenueCategories"));
const SingleAttandance = lazy(() => import("../portals/admin/Calendar/SingleAttandance"));
const Email = lazy(() => import("../portals/admin/Calendar/Email"));
const EmailParent = lazy(() => import("../portals/admin/student/MessageHistory/EmailParent"));
const StudentMessaging = lazy(() => import("../portals/admin/student/MessageHistory/StudentMessaging"));
const TeacherMessaging = lazy(() => import("../portals/admin/teacher/Messaging/TeacherMessaging"));
const NewEmail = lazy(() => import("../portals/admin/Email/NewEmail"));
const AllMessageHistory = lazy(() => import("../portals/admin/Email/AllMessageHistory"));
const AddAndEditFormEditor = lazy(() => import("../portals/admin/admissionform/AddandEditAdmissionForm"));
const Messaging = lazy(() => import("../portals/admin/Families&Invoice/Messaging"));

export const PrivateRoutes = [
    {
        path: "/dashboard",
        name: "Dashboard",
        component: Dashboard,
    },
    {
        path: "/admin",
        name: "Admin",
        component: Admin,
    },
    {
        path: "/admin/new-update",
        name: "NewUpdate",
        component: NewUpdate,
    },
    {
        path: "/teachers",
        name: "Teachers",
        component: Teacher,
    },

    {
        path: "/school",
        name: "School",
        component: School,
    },
    {
        path: "/student",
        name: "Student",
        component: Student,
    },

    {
        path: "/online-resources",
        name: "OnlineResources",
        component: OnlineResources,
    },

    {
        path: "/resources-view",
        name: "ResourcesView",
        component: ResourcesView,
    },

    {
        path: "/online-resources-view",
        name: "OnlineResourcesView",
        component: OnlineResourcesView,
    },

    {
        path: "/website",
        name: "Website",
        component: Website,
    },

    {
        path: "/website/page-editor",
        name: "PageEditor",
        component: PageEditor,
    },

    {
        path: "/website/social-media",
        name: "SocialMedia",
        component: SocialMedia,
    },

    {
        path: "/instruments",
        name: "Instruments",
        component: Instruments,
    },

    {
        path: "/purchase-plan",
        name: "PurchasePlan",
        component: PurchasePlan,
    },
    {
        path: "/admission-form",
        name: "AdminssionForm",
        component: AddFormEditor,
    },

    {
        path: "/admission-form/add-form-editor",
        name: "AdminssionForm",
        component: AddAndEditFormEditor,
    },
    {
        path: "/admission-form/edit-form-editor/:id",
        name: "AdminssionForm",
        component: AddAndEditFormEditor,
    },
    {
        path: "/manage-lessons-program",
        name: "ManageLessonsProgram",
        component: ManageMusicProgram,
    },
    {
        path: "/families-invoice",
        name: "Families & Invoice",
        component: Families,
    },
    {
        path: "/transactions-details/:id",
        name: "Families & Invoice",
        component: AccountDetails,
    },
    {
        path: "/transactions-details/:id/invoice-email/:invoiceId",
        name: "Families & Invoice",
        component: InvoiceEmail,
    },
    {
        path: "/teachers/add-teacher",
        name: "Addteacher",
        component: Addteacher,
    },

    {
        path: "/teachers/user-detail/:id",
        name: "UserDetail",
        component: UserDetail,
    },

    {
        path: "/school/add-school",
        name: "Addschool",
        component: Addschool,
    },
    {
        path: "/school/edit-school/:id",
        name: "Editschool",
        component: Editschool,
    },

    {
        path: "/student/add-student",
        name: "AddStudent",
        component: AddStudent,
    },
    {
        path: "/calendar",
        name: "Calendar",
        component: Calendar,
    },
    {
        path: "/calendar/calendar-event",
        name: "CreateEvent",
        component: CreateAndEditEvent,
    },
    {
        path: "/calendar/edit-calendar-event/:id",
        name: "EditEvent",
        component: CreateAndEditEvent,
    },
    {
        path: "/calendar/attandance/:id",
        name: "StudentAttandance",
        component: StudentAttandance,
    },
    {
        path: "/calendar/single-attandance/:eventId/:studentId",
        name: "SingleAttandance",
        component: SingleAttandance,
    },
    {
        path: "/calendar/new-email/:id",
        name: "Email",
        component: Email,
    },
    {
        path: "/student/parent-email",
        name: "Email",
        component: EmailParent,
    },
    {
        path: "/email",
        name: "Email",
        component: NewEmail,
    },

    {
        path: "/message-history",
        name: "Message History",
        component: AllMessageHistory,
    },
    {
        path: "/student/message",
        name: "StudentEmail",
        component: StudentMessaging,
    },
    {
        path: "/family/message",
        name: "InvoiceMessage",
        component: Messaging,
    },
    {
        path: "/teacher/message",
        name: "TeacherEmail",
        component: TeacherMessaging,
    },
    {
        path: "/calendar/category",
        name: "Category",
        component: Categories,
    },
    {
        path: "/family-contect",
        name: "FamilyContect",
        component: FamilyContect,
    },

    {
        path: "/automative-invoice",
        name: "AutomativeInvoice",
        component: AutomativeInvoice,
    },
    {
        path: "/student/student-detail/:id",
        name: "StudentDetail",
        component: StudentDetail,
    },

    {
        path: "/my-preferences",
        name: "My Preferences",
        component: MyPreferences,
    },

    {
        path: "/settings",
        name: "Settings",
        component: Notification,
    },

    {
        path: "/business-settings",
        name: "Business Setting",
        component: BusinessSettings,
    },
    {
        path: "/new-custom-template",
        name: "new-custom-template",
        component: NewCustomTemplate,
    },

    {
        path: "/books",
        name: "Books",
        component: Books,
    },
    {
        path: "/expenses-and-revenue",
        name: "Revenue",
        component: Revenue,
    },
    {
        path: "/expenses-and-revenue/categories",
        name: "Revenue-categories",
        component: RevenueCategories,
    },
];
