import React, { useEffect } from "react";
import { InputText } from "primereact/inputtext";
import Logo from "../../../assets/logo/logo.png";
import { Password } from "primereact/password";
import { Checkbox } from "primereact/checkbox";
import MusicLyric from "../../../assets/icons/Maskgroup.png";
import Footer from "../../layout/Footer";
import { Link } from "react-router-dom";
import Login from "../../../containers/login/index";
import { useHistory } from "react-router-dom";
import { isAuthenticated } from "../../../services/auth";

export default function AdminLogin() {
    const { handleSubmit, handleChange, adminData, err } = Login();
    const history = useHistory();

    useEffect(() => {
        if (isAuthenticated()) {
            history.push("/dashboard");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div className="bg-main" style={{ height: "100vh" }}>
                <div className="flex justify-content-center align-items-center h-full relative">
                    <img src={MusicLyric} className="absolute absolute right-0 w-3 opacity-30 top-10" alt="" />
                    <div className="md:w-4 bg-white text-center p-4 py-6 border-round-xl">
                        <img src={Logo} className="w-4 mb-2" alt="" />
                        <h1 className="text-2xl mb-4 font-bold">
                            Welcome <span className="text-main">Back!</span>
                        </h1>
                        <form className="dashboard-login" onSubmit={handleSubmit}>
                            <div className="mb-3 text-left">
                                <label className="text-xs open-sans mb-0">Email</label>
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon bg-transparent form-label">
                                        <i className="pi pi-envelope text-dark-gray"></i>
                                    </span>
                                    <InputText
                                        placeholder="Your email"
                                        className="bg-white "
                                        name="email"
                                        onChange={handleChange}
                                        value={adminData.email}
                                    />
                                </div>
                                <div className="p-error text-xs">{err && err.email}</div>
                            </div>
                            <div className="mb-3 text-left">
                                <label className="text-xs open-sans mb-0">Password</label>
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon bg-transparent form-label">
                                        <i className="pi pi-lock text-dark-gray"></i>
                                    </span>
                                    <Password
                                        placeholder="Your password"
                                        className="bg-white border-1 border-300"
                                        name="password"
                                        onChange={handleChange}
                                        value={adminData.password}
                                        toggleMask
                                    />
                                </div>
                                <div className="p-error text-xs">{err && err.password}</div>
                            </div>
                            <div className="flex justify-content-between mb-3">
                                <div className="flex align-items-center">
                                    <Checkbox
                                        inputId="ingredient1"
                                        name="rememberMe"
                                        checked={adminData?.rememberMe}
                                        onChange={handleChange}
                                    />
                                    <label htmlFor="ingredient1" className="ml-2 mb-0 text-xs open-sans">
                                        Remember Me
                                    </label>
                                </div>
                                <Link to="/forget-password" className="no-underline">
                                    <p className="text-xs font-normal open-sans text-dark mt-2">Reset Password?</p>
                                </Link>
                            </div>

                            <button className="btn bg-dark w-full p-3 border-none text-white font-semibold border-round-lg cursor-pointer">
                                Login
                            </button>
                        </form>
                    </div>
                    <img src={MusicLyric} className="absolute absolute left-0 w-3 opacity-30 bottom-0" alt="" />
                </div>
            </div>
            <Footer />
        </>
    );
}
