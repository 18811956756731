import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { sendEmailApi } from "../../redux/actions/loginAction";
import { sendOtpApi } from "../../redux/actions/loginAction";

const StepTwo = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  var base64 = require("base-64");

  const params = useParams();
  const { encodedEmail } = params;
  const history = useHistory();
  const dispatch = useDispatch();
  const email = base64.decode(encodedEmail);
  const ResendOtp = () => {
    dispatch(sendEmailApi(email, history));
  };
  const [isValid, setIsValid] = useState(false);
  const [adminOtp, setAdminOtp] = useState({
    otp: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAdminOtp({ ...adminOtp, [name]: value });
    setErr({ ...err, [name]: "" });
  };

  const [err, setErr] = useState({});
  const validateForm = () => {
    let errors = {};
    if (!adminOtp?.otp) {
      errors.otp = "OTP field is required ";
    }

    setErr(errors);
    if (Object.keys(errors).length === 0) {
      return true;
    } else {
      return false;
    }
  };

  const handleVerifyOpt = (e) => {
    e.preventDefault();

    const form = validateForm();
    if (form) {
      dispatch(sendOtpApi(adminOtp?.otp, email, history));
    }
  };

  return {
    adminOtp,
    handleChange,
    handleVerifyOpt,
    ResendOtp,
    email,
    err,
  };
};

export default StepTwo;
