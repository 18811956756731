import types from "../types";
const initialState = {
    getInvoices: [],
    getTransactions: [],
    getInvoice: [],
    viewTransaction: {},
    viewAutoInvoice: {},
    invoiceDetails: null,
};
const invoicesReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_INVOICES:
            return {
                ...state,
                getInvoices: action.payload,
            };
        case types.GET_INVOICES_TRANSACTIONS:
            return {
                ...state,
                getTransactions: action.payload,
            };
        case types.GET_INVOICE:
            return { ...state, getInvoice: action.payload }
        case types.VIEW_TRANSACTION:
            return { ...state, viewTransaction: action.payload }
        case types.VIEW_AUTO_INVOICING:
            return { ...state, viewAutoInvoice: action.payload }
        case types.SET_INVOICE_DETAILS:
            return {
                ...state,
                invoiceDetails: action.payload,
            };
        default:
            return { ...state };
    }
};

export default invoicesReducer;
