import React from "react";
import { RadioButton } from "primereact/radiobutton";

export default function RadioButtonComponent({ label, name, errorMessage, extraClassName, required, labelClassName, ...props }) {
    return (
        <div className={`flex align-items-center ml-2 ${extraClassName}`}>
            <RadioButton id={name} name={name} {...props} />
            &nbsp;&nbsp;
            <label htmlFor={name} className={`m-0 text-base open-sans font-normal text-900 ${labelClassName}`}>
                {label} {required && <span className="p-error">*</span>}
            </label>
            {errorMessage ? <small className="p-error open-sans">{errorMessage}</small> : null}
        </div>
    );
}
