import React, { Suspense } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { isAuthenticated } from "./services/auth";
import ProtectedRoute from "./Routes";
import "./App.scss";

// Css import
// import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/themes/lara-light-teal/theme.css";
import "primereact/resources/primereact.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "react-toastify/dist/ReactToastify.css";
import TopBar from "./portals/layout/TopBar";
import LayoutData from "./portals/layout/Layout";
import SideBar from "./portals/layout/SideBar";
import SidebarIcons from "./portals/layout/SidebarIcons";
import { ProgressSpinner } from "primereact/progressspinner";

function App() {
    const { isFullSidebar, setIsFullSidebar } = LayoutData();

    return (
        <>
            <div className="">
                <TopBar isFullSidebar={isFullSidebar} toggleSidebar={setIsFullSidebar} />
                <div className="grid m-0 relative top-0 overflow-hidden">
                    <div className="sidebar-container p-0 relative" style={{ width: isFullSidebar ? "15%" : "3%" }}>
                        {isFullSidebar ? <SideBar /> : <SidebarIcons />}
                    </div>
                    <div id="main-content" className="main-content p-0 " style={{ width: isFullSidebar ? "85%" : "97%" }}>
                        <Suspense
                            fallback={
                                <div>
                                    <ProgressSpinner
                                        style={{ width: "50px", height: "50px" }}
                                        strokeWidth="3"
                                        fill="var(--surface-ground)"
                                        className="absolute top-50 left-50 z-5"
                                        animationDuration=".5s"
                                    />
                                </div>
                            }
                        >
                            <Switch>
                                <Route
                                    path="/"
                                    render={(props) =>
                                        isAuthenticated() ? (
                                            <ProtectedRoute key={props.path} {...props} />
                                        ) : (
                                            <Redirect from="/" to="/" />
                                        )
                                    }
                                />
                            </Switch>
                        </Suspense>
                    </div>
                </div>
                {/* <Footer /> */}
            </div>
        </>
    );
}

export default App;
