import types from "../types";
const initialState = {
    getLessonsProgram: {},
    viewSchoolLessons: {},
    addSchoolLessons: [
        {
            number_of_student: null,
            discount: null,
            installment: null,
            number_of_installment: null,
            prepayment_discount: null,
        },
    ],
    addOnlineSchoolLessons: [],
    viewOnlineLessons: {},
    getFilterLessonList: [],
    getProgramsListofSchoolType: [],
    getOnlineStudents: [],
    getOnlineLessonGrade: [],
};
const lessonsProgramReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.FETCH_LESSONS_PROGRAM:
            return {
                ...state,
                getLessonsProgram: action.payload,
            };
        case types.ADD_SCHOOL_LESSONS:
            return {
                ...state,
                addSchoolLessons: action.payload,
            };
        case types.GET_FILTER_LESSON:
            return {
                ...state,
                getFilterLessonList: action.payload,
            };
        case "VIEW_LESSONS":
            return {
                ...state,
                viewSchoolLessons: action.payload,
            };
        case types.VIEW_ONLINE_LESSON:
            return {
                ...state,
                viewOnlineLessons: action.payload,
            };
        case types.ADD_ONLINE_SCHOOL_LESSON:
            return {
                ...state,
                addOnlineSchoolLessons: action.payload,
            };
        case types.GET_LESSON_STUDENTS:
            return {
                ...state,
                getOnlineStudents: action.payload,
            };
        case types.ONLINE_LESSON_GRADE:
            return { ...state, getOnlineLessonGrade: action.payload };
        case types.GET_LESSONS_SCHOOL_TYPE:
            return { ...state, getProgramsListofSchoolType: action.payload };

        default:
            return { ...state };
    }
};

export default lessonsProgramReducer;
