import types from "../types";
const initialState = {
    viewDashboard: {},
    overdueAttendance: [],
    pastNotes: []
};
const DashboardReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_DASHBOARD_LIST:
            return {
                ...state,
                viewDashboard: action.payload,
            };
        case types.GET_OVERDUE_ATTENDANCE:
            return { ...state, overdueAttendance: action.payload };
        case types.GET_PAST_NOTES:
            return { ...state, pastNotes: action.payload }
        default:
            return { ...state };
    }
};

export default DashboardReducer;