import React from "react";
import { InputText } from "primereact/inputtext";
import Logo from "../../../assets/logo/logo.png";
import MusicLyric from "../../../assets/icons/Maskgroup.png";
import Footer from "../../layout/Footer";
import StepTwo1 from "../../../containers/login/StepTwo";

export default function StepTwo() {
    const { adminOtp, handleChange, handleVerifyOpt, ResendOtp, email, err } = StepTwo1();

    return (
        <>
            <div className="bg-main" style={{ height: "100vh" }}>
                <div className="flex justify-content-center align-items-center h-full relative">
                    <img src={MusicLyric} className="absolute absolute right-0 w-3 opacity-30 top-10" alt="" />
                    <div className="md:w-4 bg-white text-center p-4 py-6 border-round-xl">
                        <img src={Logo} className="w-4 mb-2" alt="" />
                        <h1 className="text-2xl mb-4 font-bold">
                            We sent a code to <span className="text-main"> your email</span>
                        </h1>

                        <form className="dashboard-login">
                            <div className="mb-3 text-left p-2">
                                <p className="text-sm font-bold text-left">Enter the 6-digit verification code sent to</p>
                                <label className="open-sans font-bold text-main text-sm">{email}</label>
                                <div className="p-inputgroup mt-1 border-1 border-400 border-round-lg">
                                    <InputText name="otp" className="border-0" onChange={handleChange} value={adminOtp?.otp} />
                                </div>
                                <div className="p-error text-xs">{err && err.otp}</div>
                                <p className="text-xs text-main font-bold mt-2" onClick={ResendOtp}>
                                    Resend OTP
                                </p>
                            </div>

                            <button
                                className="btn bg-dark w-full p-3 border-non mb-3
                                e text-white font-semibold border-round-lg cursor-pointer"
                                onClick={(e) => handleVerifyOpt(e)}
                            >
                                Verify OTP
                            </button>
                        </form>

                        <p className="text-sm">If you don’t see the email in your inbox, check your spam folder.</p>
                    </div>
                    <img src={MusicLyric} className="absolute absolute left-0 w-3 opacity-30 bottom-0" alt="" />
                </div>
            </div>
            <Footer />
        </>
    );
}
