import React from "react";
import { InputText } from "primereact/inputtext";

export default function InputComponent({
    label,
    name,
    errorMessage,
    extraClassName,
    required,
    size,
    inputClass,
    labelClassName,

    ...props
}) {
    return (
        <div className={size}>
            <div className={`relative  ${extraClassName}`}>
                {label ? (
                    <label
                        htmlFor={name}
                        className={`absolute text-xs text-500 open-sans bg-white-color form-label z-1 ${labelClassName}`}
                    >
                        {label} {required && <span className="p-error">*</span>}
                    </label>
                ) : null}
                <InputText
                    id={name}
                    name={name}
                    className={`w-full border-1 border-round-lg border-400 overflow-hidden ${inputClass ? inputClass : ""} ${
                        errorMessage ? "p-invalid" : null
                    }`}
                    {...props}
                />
                {errorMessage ? <small className="p-error open-sans text-sm">{errorMessage}</small> : null}
            </div>
        </div>
    );
}
export function InputComponent1({
    label,
    name,
    errorMessage,
    extraClassName,
    required,
    size,
    inputClass,
    labelClassName,
    onChange,
    data,
    value,

    ...props
}) {
    return (
        <div className={size}>
            <div className={`relative  ${extraClassName}`}>
                {label ? (
                    <label
                        htmlFor={name}
                        className={`absolute text-xs text-500 open-sans bg-white-color form-label z-1 ${labelClassName}`}
                    >
                        {label} {required && <span className="p-error">*</span>}
                    </label>
                ) : null}
                <InputText
                    id={name}
                    name={name}
                    className={`w-full border-1 border-round-lg border-400 overflow-hidden ${inputClass ? inputClass : ""} ${
                        errorMessage ? "p-invalid" : null
                    }`}
                    value={data?.[name] || value || ""}
                    onChange={(e) => onChange && onChange({ ...e, name: e.target.name, value: e.target.value })}
                    {...props}
                />
                {errorMessage ? <small className="p-error open-sans text-sm">{errorMessage}</small> : null}
            </div>
        </div>
    );
}
