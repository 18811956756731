import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { sendEmailApi } from "../redux/actions/loginAction";

const ForgotPassword = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const history = useHistory();
  const dispatch = useDispatch();
  const [adminEmail, setAdminEmail] = useState({
    email: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setAdminEmail({ ...adminEmail, [name]: value });
    setErr({ ...err, [name]: "" });
  };
  const [err, setErr] = useState({});
  const validateForm = () => {
    let errors = {};
    if (!adminEmail?.email) {
      errors.email = "Email field is required ";
    }

    setErr(errors);
    if (Object.keys(errors).length === 0) {
      return true;
    } else {
      return false;
    }
  };

  const handleSendOpt = (e) => {
    e.preventDefault();
    const form = validateForm();
    if (form) {
      dispatch(sendEmailApi(adminEmail?.email, history));
    }
  };

  return {
    adminEmail,
    handleChange,
    handleSendOpt,
    err,
  };
};

export default ForgotPassword;
