export const options = [
    { label: "1", value: 1 },
    { label: "2", value: 2 },
    { label: "3", value: 3 },
    { label: "4", value: 4 },
    { label: "5", value: 5 },
    { label: "6", value: 6 },
    { label: "7", value: 7 },
    { label: "8", value: 8 },
    { label: "9", value: 9 },
    { label: "10", value: 10 },
    { label: "11", value: 11 },
    { label: "12", value: 12 },
];
export const titleOptions = [
    { name: "Principal", value: "principal" },
    { name: "Secretary", value: "secretary" },
    { name: "Teacher", value: "teacher" },
];

export const skillLevel = [
    {
        name: "Beginner",
        value: "beginner",
    },
    {
        name: "Intermediate",
        value: "intermediate",
    },
    {
        name: "Advanced",
        value: "advanced",
    },
];

export const modeOptions = [
    {
        name: "Invoice lessons that are upcoming (prepaid)",
        value: "prepaid",
    },
    {
        name: "Invoice lessons that have happened (postpaid)",
        value: "postpaid",
    },
];
export const Type = [
    { name: "Cash", value: "cash" },
    { name: "Cheque", value: "cheque" },
    { name: "Credit", value: "credit" },
    { name: "Debit", value: "debit" },
    { name: "E-Cheque", value: "e_cheque" },
];

export const frequencies = [
    { name: "Daily", value: "daily" },
    { name: "Weekly", value: "weekly" },
    { name: "Monthly", value: "monthly" },
    { name: "Yearly", value: "yearly" },
];

export const frequencyOptions = [
    { name: "Weekly", value: "weekly" },
    { name: "Monthly", value: "monthly" },
    { name: "Yearly", value: "yearly" },
];

export const schoolTypes = [
    { name: "Small Group Lesson (During School)", value: "during_school" },
    { name: "Small Group Lesson (After School)", value: "after_school" },
    { name: "Semi-Private Lesson (During School)", value: "semi_private_during_school" },
    { name: "Semi-Private Lesson (After School)", value: "semi_private" },
];

export const GradeOptions = [
    { label: "Kindergarten (K)", value: "k" },
    { label: "Grade 1", value: "1" },
    { label: "Grade 2", value: "2" },
    { label: "Grade 3", value: "3" },
    { label: "Grade 4", value: "4" },
    { label: "Grade 5", value: "5" },
    { label: "Grade 6", value: "6" },
    { label: "Grade 7", value: "7" },
    { label: "Grade 8", value: "8" },
    { label: "Grade 9", value: "9" },
    { label: "Grade 10", value: "10" },
    { label: "Grade 11", value: "11" },
    { label: "Grade 12", value: "12" },
];
export const InstrumentPurchaseType = [
    {
        name: "Rent",
        value: "rent",
    },
    {
        name: "Purchase",
        value: "purchase",
    },
    {
        name: "Nothing Needed",
        value: "nothing_needed",
    },
];
export const CardOptions = [
    { name: "MasterCard", value: "mastercard" },
    { name: "Visa", value: "visa" },
    { name: "AmericianExpress", value: "americianExpress" },
    { name: "Discover", value: "discover" },
];

export const generateMonthOptions = () => {
    const monthOptions = [];
    for (let i = 1; i <= 12; i++) {
        const monthNo = i < 10 ? `0${i}` : i;
        monthOptions.push({
            name: monthNo,
            value: monthNo,
        });
    }
    return monthOptions;
};

export const generateYearOptions = () => {
    const yearOptions = [];
    let year = new Date().getFullYear();
    for (let i = year; i <= year + 20; i++) {
        yearOptions.push({
            name: i,
            value: i,
        });
    }
    return yearOptions;
};

export const teacherColor = [
    "#ccdbfd",
    "#e2e2e2",
    "#ffee93",
    "#ffc09f",
    "#fcf5c7",
    "#a0ced9",
    "#adf7b6",
    "#809bce",
    "#eac4d5",
    "#e8dff5",
    "#9cadce",
    "#7ec4cf",
    "#52b2cf",
    "#d3ab9e",
    "#ff8fab",
    "#ff686b",
    "#68b6ef",
    "#ceb5b7",
    "#ff7477",
    "#dfb2f4",
    "#55d6c2",
    "#f5e960",
    "#e27396",
    "#f68c70",
    "#f6ac69",
    "#70d6ff",
];
export const genderOptions = [
    {
        name: "Male",
        value: "male",
    },
    {
        name: "Female",
        value: "female",
    },
    {
        name: "Others",
        value: "others",
    },
    {
        name: "Prefer Not To Say",
        value: "prefer_not_to_say",
    },
];

export const classType = [
    {
        name: "Small Group Lesson (During School)",
        value: "after_school",
    },
    {
        name: "Small Group Lesson (After School)",
        value: "during_school",
    },
];
export const DropOptions = [
    {
        name: "Yes",
        value: "yes",
    },
    {
        name: "No",
        value: "no",
    },
];

export const Days = [
    {
        name: "Monday",
        value: 1,
    },
    {
        name: "Tuesday",
        value: 2,
    },
    {
        name: "Wednesday",
        value: 3,
    },
    {
        name: "Thursday",
        value: 4,
    },
    {
        name: "Friday",
        value: 5,
    },
    {
        name: "Saturday",
        value: 6,
    },
];

export const fieldsToValidate = ["number_of_student", "discount", "installment", "number_of_installment", "prepayment_discount"];

export const errorMessages1 = [
    "No. of Student is required",
    "Discount is required",
    "Installment is required",
    "No. of Installment is required",
    "Prepayment Discount  is required",
];
export const requiredFields1 = ["number_of_student", "discount", "installment", "number_of_installment", "prepayment_discount"];

export const validationRules = {
    number_of_student: "No. of Student is required",
    discount: "Discount is required",
    installment: "Installment is required",
    number_of_installment: "No. of Installment is required",
    prepayment_discount: "Prepayment Discount is required",
};
export const optionsFrequency = [
    { label: "Daily", value: "daily" },
    { label: "Weekly", value: "weekly" },
    { label: "Monthly", value: "monthly" },
    { label: "Yearly", value: "yearly" },
];

export const instrumentRequiredFields = {
    name: "Instrument Name",
    purchase_amount: "Purchase Amount",
    monthly_installment: "Rent Amount",
    number_of_installment: "No. of Installment",
    deposit_amount: "Deposit Amount",
};
