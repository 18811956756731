import React from "react";
import { InputText } from "primereact/inputtext";
import Logo from "../../../assets/logo/logo.png";
import MusicLyric from "../../../assets/icons/Maskgroup.png";
import Footer from "../../layout/Footer";
import { Link } from "react-router-dom";
import ForgotPassword from "../../../containers/ForgotPassword";

export default function StepOne() {
    const { adminEmail, handleChange, handleSendOpt, err } = ForgotPassword();

    return (
        <>
            <div className="bg-main" style={{ height: "100vh" }}>
                <div className="flex justify-content-center align-items-center h-full relative">
                    <img src={MusicLyric} className="absolute absolute right-0 w-3 opacity-30 top-10" alt="" />
                    <div className="md:w-4 bg-white text-center p-4 py-6 border-round-xl">
                        <img src={Logo} className="w-4 mb-2" alt="" />
                        <h1 className="text-2xl mb-4 font-bold">
                            Forgot your <span className="text-main">Password?</span>
                        </h1>
                        <form className="dashboard-login">
                            <div className="mb-4 text-left">
                                <label className="text-xs open-sans mb-0">Email</label>
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon bg-transparent form-label">
                                        <i className="pi pi-envelope text-dark-gray"></i>
                                    </span>
                                    <InputText
                                        placeholder="Your email"
                                        className="bg-white "
                                        name="email"
                                        onChange={handleChange}
                                        value={adminEmail?.email}
                                    />
                                </div>
                                <div className="p-error text-xs">{err && err.email}</div>
                            </div>

                            <button
                                className="btn bg-dark w-full p-3 border-none text-white 
                                font-semibold border-round-lg cursor-pointer"
                                onClick={(e) => handleSendOpt(e)}
                            >
                                Send OTP
                            </button>
                        </form>

                        <div className="login-back mt-4">
                            <Link to="/" className="no-underline text-sm font-bold">
                                <span className="text-main">Back login</span>
                            </Link>
                        </div>
                    </div>
                    <img src={MusicLyric} className="absolute absolute left-0 w-3 opacity-30 bottom-0" alt="" />
                </div>
            </div>
            <Footer />
        </>
    );
}
