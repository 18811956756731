import React from "react";
import { Checkbox } from "primereact/checkbox";

export default function CheckboxComponent({
    label,
    name,
    errorMessage,
    extraClassName,
    required,
    inputClass,
    labelClassName,
    ...props
}) {
    return (
        <div className={`flex align-items-center  ml-2 ${extraClassName}`}>
            <div className="">
                <Checkbox id={name} name={name} className={` ${inputClass} ${errorMessage ? "p-invalid" : null}`} {...props} />
                {errorMessage ? <small className="p-error">{errorMessage}</small> : null}
            </div>
            {label && (
                <label htmlFor={name} className={`m-0 ml-2 open-sans ${labelClassName}`}>
                    {label} {required && <span className="p-error">*</span>}
                </label>
            )}
        </div>
    );
}
