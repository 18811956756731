import types from "../types";
const initialState = {
  getNews: [],

};
const newsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_NEWS:
      return {
        ...state,
        getNews: action.payload,
      };

    default:
      return { ...state };
  }
};

export default newsReducer;
