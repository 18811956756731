import React from "react";
import Logo from "../../../assets/logo/logo.png";
import { Password } from "primereact/password";
import MusicLyric from "../../../assets/icons/Maskgroup.png";
import Footer from "../../layout/Footer";
import StepThree1 from "../../../containers/StepThree";

export default function StepThree() {
    const { resetPassword, handleChange, handleResetPassword, err } = StepThree1();
    return (
        <>
            <div className="bg-main" style={{ height: "100vh" }}>
                <div className="flex justify-content-center align-items-center h-full relative">
                    <img src={MusicLyric} className="absolute absolute right-0 w-3 opacity-30 top-10" alt="" />
                    <div className="md:w-4 bg-white text-center p-4 py-6 border-round-xl">
                        <img src={Logo} className="w-4 mb-2" alt="" />
                        <h1 className="text-2xl mb-4 font-bold">
                            Create <span className="text-main">New Password</span>
                        </h1>
                        <form className="dashboard-login">
                            <div className="mb-3 text-left">
                                <label className="text-xs open-sans mb-0">New Password</label>
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon bg-transparent form-label">
                                        <i className="pi pi-lock text-dark-gray"></i>
                                    </span>
                                    <Password
                                        placeholder="Confirm Password"
                                        className="bg-white border-1 border-300"
                                        name="new_password"
                                        onChange={handleChange}
                                        value={resetPassword?.new_password}
                                        toggleMask
                                    />
                                </div>
                                <div className="p-error text-xs">{err && err.new_password}</div>
                            </div>

                            <div className="mb-3 text-left">
                                <label className="text-xs open-sans mb-0">Confirm New Password</label>
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon bg-transparent form-label">
                                        <i className="pi pi-lock text-dark-gray"></i>
                                    </span>
                                    <Password
                                        placeholder="Confirm Password"
                                        className="bg-white border-1 border-300"
                                        name="confirm_password"
                                        onChange={handleChange}
                                        value={resetPassword?.confirm_password}
                                        toggleMask
                                    />
                                </div>
                                <div className="p-error text-xs">{err && err.confirm_password}</div>
                            </div>

                            <button
                                className="btn bg-dark w-full p-3 border-none text-white
                 font-semibold border-round-lg cursor-pointer"
                                onClick={(e) => handleResetPassword(e)}
                            >
                                Reset Password
                            </button>
                        </form>
                    </div>
                    <img src={MusicLyric} className="absolute absolute left-0 w-3 opacity-30 bottom-0" alt="" />
                </div>
            </div>
            <Footer />
        </>
    );
}
