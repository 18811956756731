import { api } from "../../services/api";
import types from "../types";
import { isAuthenticated, authenticate } from "../../services/auth";
import Constants from "../../services/constant";
import { showToast } from "../../utils/showToast";
import { showLoaderAction, hideLoaderAction } from "./loaderAction";
import { uploadImages } from "./uploadFiles";
import { checkfun1, removeEmpty } from "../../utils/commonFunctions";
var base64 = require("base-64");

export const loginAction = (adminData, history) => async (dispatch) => {
    dispatch(showLoaderAction());
    const { email, password, rememberMe } = adminData;

    const payload = {
        email,
        password,
    };

    const res = await api("post", Constants.END_POINT.LOGIN, payload);
    var encoded = base64.encode(password);

    if (res.success) {
        if (rememberMe === true) {
            localStorage.setItem("rememberMe", rememberMe);
            localStorage.setItem("email", rememberMe ? email : "");
            localStorage.setItem("Detail", rememberMe ? encoded : "");
        } else {
            localStorage.removeItem("email");
            localStorage.removeItem("Detail");
            localStorage.setItem("rememberMe", rememberMe);
        }

        showToast(["Login successfully"], "success");
        if (res.data) {
            dispatch({
                type: types.ADMIN_DETAIL,
                payload: res.data,
            });
            authenticate(res.data.token, () => {
                if (isAuthenticated()) {
                    history.replace("/dashboard");
                }
                if (!isAuthenticated) {
                    history.push("/");
                }
            });
        }
    } else {
        showToast([res.message], "error");
    }
    dispatch(hideLoaderAction());
};

export const logoutAction = () => (dispatch) => {
    dispatch({
        type: types.ADMIN_DETAIL,
        payload: {
            token: "",
        },
    });
};

export const sendEmailApi = (adminEmail, history) => async (dispatch) => {
    dispatch(showLoaderAction());

    var encodedEmail = base64.encode(adminEmail);
    const payload = { email: adminEmail };
    const res = await api("post", Constants.END_POINT.SENT_OTP, payload);
    dispatch(hideLoaderAction());

    if (res.success) {
        showToast([res.message], "success");
        history.push(`/verify-otp/${encodedEmail}`);
    } else {
        showToast([res.message], "error");
    }
};
export const getProfileApi = () => async (dispatch) => {
    dispatch(showLoaderAction());

    const res = await api("get", Constants.END_POINT.GET_PROFILE);
    dispatch(hideLoaderAction());

    if (res.success) {
        dispatch({
            type: types.GET_PROFILE,
            payload: res.data,
        });
    } else {
        showToast([res.message], "error");
    }
};

export const sendOtpApi = (otp, email, history) => async (dispatch) => {
    dispatch(showLoaderAction());
    var encodedEmail = base64.encode(email);

    const payload = { email: email, otp: otp };
    const res = await api("post", Constants.END_POINT.VERIFY_OTP, payload);
    dispatch(hideLoaderAction());

    if (res.success) {
        showToast([res.message], "success");
        history.push(`/reset-password/${encodedEmail}`);
    } else {
        showToast([res.message], "error");
    }
};
export const sendResetPasswordApi = (email, password, history) => async (dispatch) => {
    dispatch(showLoaderAction());

    const payload = { email: email, new_password: password };
    const res = await api("post", Constants.END_POINT.RESET_PASSWORD, payload);
    dispatch(hideLoaderAction());

    if (res.success) {
        showToast([res.message], "success");
        history.push("/");
    } else {
        showToast([res.message], "error");
    }
};
export const ChangePasswordAction = (data, setVisible) => async (dispatch) => {
    dispatch(showLoaderAction());
    const payload = { password: data?.current_password, new_password: data?.password };

    const res = await api("post", Constants.END_POINT.CHANGE_PASSWORD, payload);

    if (res.success) {
        showToast([res.message], "success");
        setVisible(false);
    } else {
        showToast([res.message], "error");
    }

    dispatch(hideLoaderAction());
};

export const editprofile = (profile, history) => async (dispatch) => {
    dispatch(showLoaderAction());

    let payload = {
        ...profile,
        phone_number: profile.phone_number?.replace(/[-()]/g, ""),
        projected_monthly_revenue: checkfun1(profile.projected_monthly_revenue),
        payment_received_this_month: checkfun1(profile.payment_received_this_month),
        overdue_attendance: checkfun1(profile.overdue_attendance),
        registration_widget: checkfun1(profile.registration_widget),
        disables_email_reminders: checkfun1(profile.disables_email_reminders),
    };
    if (payload?.picture_url?.length) {
        // console.log("payload?.picture_url>>", payload?.picture_url);
        payload.picture_url = await uploadImages(payload.picture_url, "admin_pictures");
        // console.log(payload.picture_url);
        payload.picture_url = payload.picture_url[0];
        payload.is_picture_delete = 0;

        if (payload.picture_url.includes("http")) {
            delete payload.picture_url;
        }
    } else {
        payload.picture_url = "";
        payload.is_picture_delete = 1;
    }

    const final = removeEmpty(payload);

    const res = await api("post", Constants.END_POINT.EDIT_PROFILE, final);
    if (res.success) {
        // history.goBack();
        showToast([res.message], "success");
        dispatch(getProfileApi());
    } else {
        showToast([res.message], "error");
    }
    dispatch(hideLoaderAction());
};
export const LogoutAllDevicesAction = () => async (dispatch) => {
    dispatch(showLoaderAction());

    const res = await api("get", Constants.END_POINT.LOGOUT_ALL_DEVICES);

    if (res.success) {
        showToast([res.message], "success");
    } else {
        showToast([res.message], "error");
    }

    dispatch(hideLoaderAction());
};
