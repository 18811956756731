import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import App from "./App";
import store from "./redux";
import { HashRouter, Route, Switch } from "react-router-dom/cjs/react-router-dom.min";
import Loader from "./utils/Loader";
import { ConfirmDialog } from "primereact/confirmdialog";
import { ToastContainer } from "react-toastify";
import AdminLogin from "./portals/admin/login";
import StepOne from "./portals/admin/ForgetPassword/StepOne";
import StepTwo from "./portals/admin/ForgetPassword/StepTwo";
import StepThree from "./portals/admin/ForgetPassword/StepThree";
import { InvoiceGenerate } from "./components/Invoice/InvoiceGenerate";
import InvoicePayments from "./components/Invoice/InvoicePayments";

ReactDOM.render(
    <Provider store={store}>
        <HashRouter>
            <Loader />
            <ConfirmDialog />
            <Switch>
                <Route path="/" exact={true} component={AdminLogin} />
                <Route path="/forget-password" component={StepOne} />
                <Route path="/verify-otp/:encodedEmail" component={StepTwo} />
                <Route path="/reset-password/:encodedEmail" component={StepThree} />
                <Route path="/invoice-generate" component={InvoiceGenerate} />
                <Route path="/invoice-payments" component={InvoicePayments} />
                <App />
            </Switch>
        </HashRouter>
        <ToastContainer
            position="top-right"
            autoClose={2000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
        />
    </Provider>,
    document.getElementById("root")
);
