import types from "../types";
const initialState = {
  form: [],
  getForms: [],
  viewForm: {},
  addForm: {},
};
const formbuilderReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FORM_BUILDER:
      return {
        ...state,
        addForm: action.payload,
      };
    case types.FETCH_FORM:
      return {
        ...state,
        getForms: action.payload,
      };
    case types.VIEW_FORM:
      return {
        ...state,
        viewForm: action.payload,
      };

    default:
      return { ...state };
  }
};

export default formbuilderReducer;
