import Constants from "../../services/constant";
import { api } from "../../services/api";
import { showLoaderAction, hideLoaderAction } from "./loaderAction";
import types from "../types";
import { showToast } from "../../utils/showToast";
import moment from "moment";

const getInvoicesAction = (date, setLoading) => async (dispatch, getState) => {
    let { invoices } = getState();
    // if (setLoading) {
    //     setLoading(true)
    // }
    if (!invoices.getInvoices.length) {
        dispatch(showLoaderAction());
        // if (setLoading) {
        //     setLoading(true)
        // }
    }
    const res = await api("post", Constants.END_POINT.GET_INVOICES, { date: moment(date).format("DD-MM-YYYY") }, {});

    if (res.success) {
        dispatch({
            type: types.GET_INVOICES,
            payload: res.data,
        });
    }
    dispatch(hideLoaderAction());
};
export const disableInvoiceAction = (selectedItems) => async (dispatch) => {
    const payload = {
        parent_user_id: selectedItems,
    };
    const res = await api("post", Constants.END_POINT.DISABLE_AUTO_INVOICE, payload, {});
    if (res.success) {
        showToast([res.message], "success");

        dispatch(getInvoicesAction(new Date()));
    } else {
        showToast([res.message], "error");
    }
};

const getInvoicesTransactionsAction = (id, date) => async (dispatch) => {
    dispatch(showLoaderAction());

    const url = date
        ? Constants.END_POINT.GET_INVOICE_TRANSACTIONS + id + "/" + moment(date).format("DD-MM-YYYY")
        : Constants.END_POINT.GET_INVOICE_TRANSACTIONS + id;
    const res = await api("get", url, {});
    if (res.success) {
        dispatch({
            type: types.GET_INVOICES_TRANSACTIONS,
            payload: res.data,
        });
    } else {
        dispatch({
            type: types.GET_INVOICES_TRANSACTIONS,
            payload: [],
        });
    }

    dispatch(hideLoaderAction());
};
const getInvoiceAction = (id) => async (dispatch) => {
    dispatch(showLoaderAction());
    const res = await api("get", Constants.END_POINT.GET_INVOICE + id, {});
    if (res.success) {
        dispatch({
            type: types.GET_INVOICE,
            payload: res.data,
        });
    } else {
        dispatch({
            type: types.GET_INVOICE,
            payload: [],
        });
    }

    dispatch(hideLoaderAction());
};

const addTransaction =
    ({ data, checkedDays }, id, date, next) =>
    async (dispatch) => {
        dispatch(showLoaderAction());
        if (typeof data?.family == "number") {
            let arr = [];
            arr.push(data?.family);
            data.family = arr;
        }

        const payload = {
            ...data,
            taxes: data?.taxes ? 1 : 0,
            is_repeat: data?.is_repeat ? 1 : 0,
            days: data?.frequency === "daily" ? checkedDays : [],
            every_weeks: data?.frequency === "weekly" ? data?.per_event : "",
            every_months: data?.frequency === "monthly" ? data?.per_event : "",
            month_day: "",
            every_years: data?.frequency === "yearly" ? data?.per_event : "",
            date: moment(data.date).format("DD-MM-YYYY"),
            repeat_until: data.repeat_until ? moment(data.repeat_until).format("DD-MM-YYYY") : "",
        };

        const res = await api("post", Constants.END_POINT.ADD_TRANSACTION, payload);
        if (res.success) {
            showToast([res.message], "success");
            if (id) {
                dispatch(getInvoicesTransactionsAction(id, date));
            } else {
                dispatch(getInvoicesAction());
            }
            next();
        } else {
            showToast([res.message], "error");
        }

        dispatch(hideLoaderAction());
    };

const editTransaction =
    ({ data, checkedDays }, id, date, type, transactionId, next) =>
    async (dispatch) => {
        dispatch(showLoaderAction());
        if (typeof data?.family == "number") {
            let arr = [];
            arr.push(data?.family);
            data.family = arr;
        }

        const payload = {
            ...data,
            taxes: data?.taxes ? 1 : 0,
            edit_type: type,
            transaction_id: transactionId,
            is_repeat: data?.is_repeat ? 1 : 0,
            days: data?.frequency === "daily" ? checkedDays : [],
            every_weeks: data?.frequency === "weekly" ? data?.per_event : "",
            every_months: data?.frequency === "monthly" ? data?.per_event : "",
            month_day: "",
            every_years: data?.frequency === "yearly" ? data?.per_event : "",
            date: moment(data.date).format("DD-MM-YYYY"),
            repeat_until: data.repeat_until ? moment(data.repeat_until).format("DD-MM-YYYY") : "",
        };

        const res = await api("post", Constants.END_POINT.EDIT_TRANSACTION, payload);
        if (res.success) {
            showToast([res.message], "success");
            if (id) {
                dispatch(getInvoicesTransactionsAction(id, date));
            } else {
                dispatch(getInvoicesAction());
            }
            next();
        }

        dispatch(hideLoaderAction());
    };

const addInvoices = (id, data, setInvoiceVisible) => async (dispatch) => {
    dispatch(showLoaderAction());

    const payload = {
        ...data,
        start_date: data?.start_date ? moment(data?.start_date).format("DD-MM-YYYY") : "",
        end_date: data?.end_date ? moment(data?.end_date).format("DD-MM-YYYY") : "",
        include_previous: data?.include_previous ? 1 : 0,
        invoice_date: data?.invoice_date ? moment(data?.invoice_date).format("DD-MM-YYYY") : "",
        due_date: data?.due_date ? moment(data?.due_date).format("DD-MM-YYYY") : "",
    };
    const res = await api("post", Constants.END_POINT.ADD_INVOICE, payload);
    if (res.success) {
        showToast([res.message], "success");
        if (id) {
            dispatch(getInvoiceAction(id));
        } else {
            dispatch(getInvoicesAction());
        }
        setInvoiceVisible(false);
    } else {
        showToast([res.message], "error");
    }

    dispatch(hideLoaderAction());
};

const addAutoInvoices = (id, data, setAutoInvoice) => async (dispatch) => {
    dispatch(showLoaderAction());

    const payload = {
        ...data,
        day: data?.frequency === "monthly" ? data?.day : "",
        start_date: data?.start_date ? moment(data?.start_date).format("DD-MM-YYYY") : "",
        include_previous: data?.include_previous ? 1 : 0,
        auto_email: data?.auto_email ? 1 : 0,
    };
    const res = await api("post", Constants.END_POINT.ADD_AUTO_INVOICE, payload);
    if (res.success) {
        showToast([res.message], "success");
        dispatch(getInvoicesAction(new Date()));
        setAutoInvoice(false);
    }

    dispatch(hideLoaderAction());
};

export const getInvoiceDetails = (parentId, invoiceId, type, getPDF) => async (dispatch) => {
    const data = await api("GET", `${Constants.END_POINT.VIEW_INVOICE}/${parentId}/${invoiceId}`);
    if (data.success === true) {
        const tableData = data?.data?.invoice_data?.map((invoice) => ({
            date: invoice.date.split(" ")[0],
            description: invoice.description,
            charges: invoice.payment_type === "charge" ? "$" + invoice.amount : "-",
            payment: invoice.payment_type === "payment" ? "$" + invoice.amount : "-",
        }));

        const invoiceDetails = {
            date: data?.data?.date?.split(" ")[0],
            invoiceId: data?.data?.quickbook_invoice_id,
            billedTo: data?.data?.parent_user_name,
            previousBalance: data?.data?.previous_balance,
            payment: data?.data?.total_payment,
            newCharges: data?.data?.total_charges,
            total: data?.data?.total_due,
            data: tableData,
            status: data?.data?.status,
            address: {
                address: data?.data?.parent_user?.address,
                city: data?.data?.parent_user?.city,
                state: data?.data?.parent_user?.state,
                zipCode: data?.data?.parent_user?.zip_code,
            },
        };

        dispatch({
            type: types.SET_INVOICE_DETAILS,
            payload: invoiceDetails,
        });
        if (type === "download") {
            getPDF();
        }
    }
};
const viewAutoInvoicingAction = (id) => async (dispatch) => {
    dispatch(showLoaderAction());
    const res = await api("get", Constants.END_POINT.VIEW_AUTO_INVOICING + id, {});
    if (res.success) {
        dispatch({
            type: types.VIEW_AUTO_INVOICING,
            payload: res.data,
        });
    } else {
        dispatch({
            type: types.VIEW_AUTO_INVOICING,
            payload: {},
        });
    }

    dispatch(hideLoaderAction());
};

const deleteInvoiceAction = (eventId, id, setInvoiceView) => async (dispatch) => {
    dispatch(showLoaderAction());

    const res = await api("get", Constants.END_POINT.DELETE_INVOICE + eventId);
    if (res.success) {
        showToast([res.message], "success");
        if (setInvoiceView) {
            setInvoiceView(false);
        }
        if (id) {
            dispatch(getInvoiceAction(id));
        } else {
            dispatch(getInvoicesAction());
        }
    }

    dispatch(hideLoaderAction());
};
const voidInvoiceAction = (eventId, id, setInvoiceView) => async (dispatch) => {
    dispatch(showLoaderAction());

    const res = await api("get", Constants.END_POINT.VOID_INVOICE + eventId);
    if (res.success) {
        showToast([res.message], "success");
        if (setInvoiceView) {
            setInvoiceView(false);
        }
        if (id) {
            dispatch(getInvoiceAction(id));
        } else {
            dispatch(getInvoicesAction());
        }
    } else {
        showToast([res.message], "error");
    }

    dispatch(hideLoaderAction());
};
const PaymentInvoiceAction = (ParentUserId, InvoiceId, type, getPDF, CardId, setMakePayment) => async (dispatch) => {
    dispatch(showLoaderAction());

    const res = await api("get", Constants.END_POINT.PAYMENT_INVOICE + ParentUserId + "/" + InvoiceId + "/" + CardId);
    if (res.success) {
        showToast([res.message], "success");
        dispatch(getInvoiceDetails(ParentUserId, InvoiceId, type, getPDF));
        setMakePayment(false);
    } else {
        showToast([res.message], "error");
    }

    dispatch(hideLoaderAction());
};
const viewTransactionAction = (id) => async (dispatch) => {
    dispatch(showLoaderAction());
    const res = await api("get", Constants.END_POINT.VIEW_TRANSACTION + id, {});
    if (res.success) {
        dispatch({
            type: types.VIEW_TRANSACTION,
            payload: res.data,
        });
    } else {
        dispatch({
            type: types.VIEW_TRANSACTION,
            payload: [],
        });
    }

    dispatch(hideLoaderAction());
};
const deleteTransactionAction = (transactionId, type, setOpenDeletePopup, id, date) => async (dispatch) => {
    const payload = { transaction_id: transactionId, delete_type: type };
    const res = await api("post", Constants.END_POINT.DELETE_TRANSACTION, payload, {});
    if (res.success) {
        showToast([res.message], "success");
        setOpenDeletePopup(false);
        if (id) {
            dispatch(getInvoicesTransactionsAction(id, date));
        } else {
            dispatch(getInvoicesAction());
        }
    } else {
        showToast([res.message], "error");
    }
};

export {
    getInvoicesAction,
    getInvoicesTransactionsAction,
    addTransaction,
    getInvoiceAction,
    addInvoices,
    deleteInvoiceAction,
    addAutoInvoices,
    viewTransactionAction,
    editTransaction,
    deleteTransactionAction,
    viewAutoInvoicingAction,
    PaymentInvoiceAction,
    voidInvoiceAction,
};
