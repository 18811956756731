import React from "react";
import { Dropdown } from "primereact/dropdown";

export default function DropDownComponent({
    label,
    name,
    errorMessage,
    extraClassName,
    required,
    size,
    inputClass,
    labelClassName,
    ...props
}) {
    return (
        <div className={size}>
            <div className={`mb-2 relative ${extraClassName}`}>
                {label && (
                    <label
                        htmlFor={name}
                        className={`absolute text-xs text-500 z-3 open-sans bg-white-color form-label ${labelClassName}`}
                    >
                        {label} {required && <span className="p-error">*</span>}
                    </label>
                )}
                <div className="">
                    <Dropdown
                        id={name}
                        name={name}
                        filter
                        resetFilterOnHide={true}
                        className={`w-full border-400 text-black border-round-lg ${inputClass} ${
                            errorMessage ? "p-invalid" : null
                        }`}
                        {...props}
                    />
                    {errorMessage ? <small className="p-error open-sans text-sm">{errorMessage}</small> : null}
                </div>
            </div>
        </div>
    );
}
