import { useState } from "react";
import Home from "../../assets/icons/home.png";
import Admin from "../../assets/icons/admin.png";
import Teacher from "../../assets/icons/teacher.png";
import School from "../../assets/icons/school.png";
import Student from "../../assets/icons/student.png";
import Calendar from "../../assets/icons/calender.png";
import Laptop from "../../assets/icons/laptop.png";
import Browser from "../../assets/icons/browser.png";
import AdmissionForm from "../../assets/icons/form.png";
import Setting from "../../assets/icons/setting.png";
import InstrumentIcon from "../../assets/icons/musicinstrument.png";
import MusicProgramIcon from "../../assets/icons/MusicProgram.png";
import BillIcon from "../../assets/icons/bill.png";
import ExpenseIcon from "../../assets/icons/expense.png";

const LayoutData = () => {
    const [isFullSidebar, setIsFullSidebar] = useState(true);

    const menuItems = [
        {
            name: "Dashboard",
            link: "/dashboard",
            icon: Home,
            iconWidth: "17px",
            iconHeight: "19px",
        },
        {
            name: "Admin",
            link: "/admin",
            icon: Admin,
            iconWidth: "29px",
            iconHeight: "29px",
        },
        {
            name: "Teachers",
            link: "/teachers",
            icon: Teacher,
            iconWidth: "24px",
            iconHeight: "24px",
        },
        {
            name: "Schools",
            link: "/school",
            icon: School,
            iconWidth: "24px",
            iconHeight: "24px",
        },

        {
            name: "Students",
            link: "/student",
            icon: Student,
            iconWidth: "24px",
            iconHeight: "24px",
        },
        {
            name: "Calendar",
            link: "/calendar",
            icon: Calendar,
            iconWidth: "24px",
            iconHeight: "24px",
        },
        {
            name: "Online Resources",
            link: "/online-resources",
            icon: Laptop,
            iconWidth: "20px",
            iconHeight: "21px",
        },
        {
            name: "Website",
            link: "/website",
            icon: Browser,
            iconWidth: "24px",
            iconHeight: "24px",
        },
        {
            name: "Admission Form",
            link: "/admission-form",
            icon: AdmissionForm,
            iconWidth: "24px",
            iconHeight: "24px",
        },
        {
            name: "Instruments",
            link: "/instruments",
            icon: InstrumentIcon,
            iconWidth: "24px",
            iconHeight: "24px",
            // subMenu: [
            //     {
            //         name: "Purchase Plan",
            //         link: "/purchase-plan",
            //     },
            // ],
        },
        {
            name: "Manage Profitables",
            link: "/manage-lessons-program",
            icon: MusicProgramIcon,
            iconWidth: "24px",
            iconHeight: "24px",
            subMenu: [
                {
                    name: "Lessons Program",
                    link: "/manage-lessons-program",
                },
                {
                    name: "Books",
                    link: "/books",
                },
            ],
        },
        {
            name: "Families & Invoices",
            link: "/families-invoice",
            icon: BillIcon,
            iconWidth: "20px",
            iconHeight: "21px",
        },
        {
            name: "Expenses & Revenue",
            link: "/expenses-and-revenue",
            icon: ExpenseIcon,
            iconWidth: "20px",
            iconHeight: "21px",
        },
        {
            name: "Settings",
            link: "/settings",
            icon: Setting,
            iconWidth: "17px",
            iconHeight: "17px",
            subMenu: [
                {
                    name: "My Preferences",
                    link: "/my-preferences",
                },
                {
                    name: "Business Settings",
                    link: "/business-settings",
                },
            ],
        },
    ];
    return { menuItems, isFullSidebar, setIsFullSidebar };
};
export default LayoutData;
