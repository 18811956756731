import { Dialog } from "primereact/dialog";
import React, { useEffect, useState } from "react";
import InputComponent from "../../../../components/Fields/InputField";
import DropDownComponent from "../../../../components/Fields/CustomDropdown";
import CheckboxComponent from "../../../../components/Fields/Checkbox";
import { Country, State } from "country-state-city";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { firstLetterToUppercase } from "../../../../utils/regex";
import Edit from "../../../../assets/icons/edit.png";
import Delete from "../../../../assets/icons/delete.png";
import { usePaymentInputs } from "react-payment-inputs";
import images from "react-payment-inputs/images";
import { CardOptions, generateMonthOptions, generateYearOptions } from "../../../../utils/constants";

const AddAndEditCard = ({
    openCard,
    setOpenCard,
    selectedItems,
    setSelectedItems,
    familydetail,
    cardDetail,
    setCardDetail,
    deletedIds,
    setDeletedIds,
}) => {
    const { meta, getCardNumberProps, getCardImageProps, getCVCProps } = usePaymentInputs();
    const handleNumberChange = (e) => {
        setCardDetail({ ...cardDetail, card_number: e.target.value });
    };
    const handleCsvChange = (e) => {
        setCardDetail({ ...cardDetail, card_security_code: e.target.value });
    };

    const [countryStates, setCountryStates] = useState([]);
    useEffect(() => {
        getUpdatedStates();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cardDetail?.country]);

    const getUpdatedStates = () => {
        let res = State.getStatesOfCountry(cardDetail?.country);
        setCountryStates(res);
    };
    const [err, setErr] = useState({});
    const [id, setId] = useState("");

    const validateForm = () => {
        let errors = {};
        if (!cardDetail?.card_type) {
            errors.card_type = "Card Type is required";
        }
        if (meta.erroredInputs.cardNumber) {
            errors.card_number = meta.erroredInputs.cardNumber;
        }
        if (!cardDetail?.expiry_month) {
            errors.expiryDate = "Expiry Month is required";
        } else if (!cardDetail?.expiry_year) {
            errors.expiryDate = "Expiry Year is required";
        } else if (!isExpirationDateValid) {
            errors.expiryDate = "The expiration date is invalid. Please enter a valid expiration date.";
        }
        if (meta.erroredInputs.cvc) {
            errors.card_security_code = meta.erroredInputs.cvc;
        }

        setErr(errors);
        if (Object.keys(errors).length === 0) {
            return true;
        } else {
            return false;
        }
    };

    useEffect(() => {
        if (familydetail?.[0]?.card?.length) {
            setSelectedItems(
                familydetail[0].card.map((item) => ({
                    card_holder_name: item.card_holder_name,
                    card_type: item.card_type,
                    card_number: item.card_number,
                    expiry_month: item.card_exp_month,
                    expiry_year: parseInt(item.card_exp_year),
                    card_security_code: item.card_security_code,
                    country: item.country,
                    state: item.state,
                    city: item.city,
                    address_line_1: item.address_line_1,
                    address_line_2: item.address_line_2,
                    zip_code: item.zip_code,
                    id: parseInt(item.id),
                    auto_pay: item?.auto_pay === 1 ? true : false,
                }))
            );
            // setId(familydetail[0].card[0]?.id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [familydetail]);

    const handleChange = (e) => {
        const { name, value } = e.target;

        setCardDetail({ ...cardDetail, [name]: value });
        setErr({ ...err, [name]: "" });
    };

    const handleChange1 = (e) => {
        const { name } = e.target;
        setCardDetail({ ...cardDetail, [name]: e.value });
        setErr({ ...err, card_type: "", expiryDate: "" });
        // setErr({ ...err, ["expiryDate"]: "" });
    };
    const [countries] = useState(Country.getAllCountries());

    const handleCheck = (name) => {
        setCardDetail({ ...cardDetail, [name]: !cardDetail[name] });
    };

    const handleSave = (e) => {
        e.preventDefault();

        const form = validateForm();

        if (form) {
            if (id) {
                setOpenCard(false);

                let ne = [...selectedItems];
                ne[id - 1] = cardDetail;
                setSelectedItems([...ne]);
                setId("");
                setCardDetail({
                    card_holder_name: "",
                    card_type: "",
                    card_number: "",
                    expiry_month: "",
                    expiry_year: "",
                    card_security_code: "",
                    country: [],
                    state: [],
                    city: "",
                    address_line_1: "",
                    address_line_2: "",
                    zip_code: "",
                    auto_pay: false,
                });
            } else {
                setOpenCard(false);
                setSelectedItems((prev) => [...prev, cardDetail]);
                setCardDetail({
                    card_holder_name: "",
                    card_type: "",
                    card_number: "",
                    expiry_month: "",
                    expiry_year: "",
                    card_security_code: "",
                    country: [],
                    state: [],
                    city: "",
                    address_line_1: "",
                    address_line_2: "",
                    zip_code: "",
                    auto_pay: false,
                });
            }
        }
    };
    const AccountTemplate = (rowData) => {
        return <>**** **** **** {rowData?.card_number?.slice(-4)}</>;
    };

    const TypeTemplate = (rowData) => {
        return <>{firstLetterToUppercase(rowData?.card_type)}</>;
    };
    const handleRemoveItem = (idx) => {
        const temp = [...selectedItems];
        // temp.splice(idx, 1);
        setSelectedItems((prevState) => {
            const filteredCards = prevState.filter((item, index) => index !== idx);
            let deletedID = prevState[idx].id;
            if (deletedID !== undefined) {
                setDeletedIds((prevDeletedIDs) => [...prevDeletedIDs, deletedID]);
            }
            return filteredCards;
        });

        // setSelectedItems(temp);
    };

    // Card Validations

    const month = cardDetail?.expiry_month;
    const year = cardDetail?.expiry_year;

    const isExpirationDateValid = validateExpirationDate({
        month,
        year,
    });

    function validateExpirationDate() {
        const month = parseInt(cardDetail?.expiry_month);
        const year = parseInt(cardDetail?.expiry_year);
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();

        const currentMonth = currentDate.getMonth() + 1; // Add 1 because getMonth() returns 0-based index
        return year > currentYear || (year === currentYear && month >= currentMonth);
    }
    const handleEditItem = (indx, rowData) => {
        setOpenCard(true);
        setId(indx + 1);
        setCardDetail({
            ...cardDetail,
            card_holder_name: rowData.card_holder_name,
            card_type: rowData.card_type,
            card_number: rowData.card_number,
            expiry_month: rowData.expiry_month,
            expiry_year: rowData.expiry_year,
            card_security_code: rowData.card_security_code,
            country: rowData.country,
            state: rowData.state,
            city: rowData.city,
            address_line_1: rowData.address_line_1,
            address_line_2: rowData.address_line_2,
            zip_code: rowData.zip_code,
            auto_pay: rowData.auto_pay,
        });
    };

    const ActionTemplate = (rowData, index) => {
        return (
            <>
                <img
                    src={Edit}
                    alt=""
                    width="15px"
                    height="16px"
                    className="product-image ml-3 cusor-pointer"
                    onClick={() => handleEditItem(index.rowIndex, rowData)}
                />
                <img
                    src={Delete}
                    alt=""
                    width="15px"
                    height="16px"
                    className="product-image ml-3 cusor-pointer"
                    onClick={() => handleRemoveItem(index.rowIndex)}
                />
            </>
        );
    };

    return (
        <>
            <Dialog
                header="Add New Card"
                className="assign-student border-round-lg overflow-hidden text-white"
                visible={openCard}
                style={{ width: "48vw" }}
                draggable={false}
                resizable={false}
                onHide={() => {
                    setOpenCard(false);
                    setErr({});
                    setCardDetail({});
                }}
            >
                <div className="p-3">
                    <InputComponent
                        label=" Card Holder Name"
                        size="mb-3"
                        placeholder="Enter Name"
                        name="card_holder_name"
                        value={cardDetail?.card_holder_name}
                        onChange={handleChange}
                    />
                    <DropDownComponent
                        options={CardOptions}
                        label=" Card Type"
                        size="mb-3"
                        placeholder="Select Card Type"
                        optionLabel="name"
                        required
                        name="card_type"
                        value={cardDetail?.card_type}
                        onChange={handleChange1}
                        errorMessage={err?.card_type}
                    />
                    <div className="mb-3">
                        <div className="relative">
                            <svg {...getCardImageProps({ images })} className="absolute card-svg" />

                            <input
                                className="w-full p-2 border-round-lg border-1 border-400"
                                name="card_number"
                                {...getCardNumberProps({ onChange: handleNumberChange })}
                                value={cardDetail?.card_number}
                            />
                        </div>
                        <div className="text-danger text-sm">{err?.card_number}</div>
                    </div>

                    {/* <InputMaskComponent
                        label="Card Number"
                        placeholder=" Enter Card Number "
                        name="card_number"
                        id="cardNum"
                        mask="9999 9999 9999 9999"
                        labelClassName="absolute text-xs text-500 open-sans  bg-white form-label"
                        extraClassName=" relative"
                        inputClass="w-full border-1 p-2 border-round-lg border-700 mb-3"
                        required
                        value={cardDetail?.card_number}
                        onChange={handleChange}
                        errorMessage={err?.card_number}
                    /> */}

                    <div className="grid flex mb-2 align-items-center">
                        <div className="md:col-4">
                            <p className=" font-bold">Expiry Date (MM/YY)</p>
                        </div>
                        <DropDownComponent
                            size="md:col-3"
                            placeholder="Select Month"
                            required
                            optionLabel="name"
                            options={generateMonthOptions()}
                            name="expiry_month"
                            value={cardDetail?.expiry_month}
                            onChange={handleChange1}
                        />
                        <DropDownComponent
                            size="md:col-3"
                            placeholder="Select Year"
                            required
                            options={generateYearOptions()}
                            optionLabel="name"
                            name="expiry_year"
                            value={cardDetail?.expiry_year}
                            onChange={handleChange1}
                        />
                        <small className="text-danger open-sans text-sm mb-2">
                            {err?.expiryDate && <p>{err?.expiryDate}</p>}
                        </small>
                    </div>
                    <div className="mb-3">
                        <div className="relative">
                            <input
                                className="w-full p-2 border-round-lg border-1 border-400"
                                {...getCVCProps({ onChange: handleCsvChange })}
                                value={cardDetail?.card_security_code}
                                placeholder="CVV"
                            />
                        </div>
                        <div className="text-danger text-sm">{err?.card_security_code}</div>
                    </div>
                    {/* <InputComponent
                        size="mb-3"
                        label="Card security Code"
                        name="card_security_code"
                        value={cardDetail?.card_security_code}
                        onChange={handleChange}
                    /> */}
                    <DropDownComponent
                        label="Country"
                        size="mb-3"
                        name="country"
                        optionLabel="name"
                        options={
                            countries?.length &&
                            countries?.map((country, i) => {
                                return { name: country.name, value: country.isoCode };
                            })
                        }
                        value={cardDetail?.country}
                        onChange={handleChange1}
                    />
                    <DropDownComponent
                        label="State/Province"
                        size="mb-3"
                        name="state"
                        optionLabel="name"
                        options={countryStates?.map((state, i) => {
                            return { name: state.name, value: state.isoCode };
                        })}
                        value={cardDetail?.state}
                        onChange={handleChange1}
                    />
                    <InputComponent label="City" size="mb-3" name="city" value={cardDetail?.city} onChange={handleChange} />
                    <InputComponent
                        label="Address Line 1"
                        size="mb-3"
                        name="address_line_1"
                        value={cardDetail?.address_line_1}
                        onChange={handleChange}
                    />
                    <InputComponent
                        label="Address Line 2"
                        size="mb-3"
                        name="address_line_2"
                        value={cardDetail?.address_line_2}
                        onChange={handleChange}
                    />
                    <InputComponent
                        label="Zip/Postal Code"
                        size="mb-3"
                        name="zip_code"
                        value={cardDetail?.zip_code}
                        onChange={handleChange}
                        keyfilter="int"
                    />
                    <div className="d-flex">
                        <p className="font-bold">Auto-Pay</p>
                        <CheckboxComponent
                            label="Automatically pay emailed invoices"
                            name="auto_pay"
                            checked={cardDetail?.auto_pay}
                            onChange={() => handleCheck("auto_pay")}
                        />
                    </div>
                    <div className="flex justify-content-end">
                        <button
                            className="open-sans font-semibold text-lg bg-main text-900  border-none border-round-lg p-2 px-4"
                            onClick={handleSave}
                        >
                            Save
                        </button>
                        <button
                            className="open-sans font-semibold text-lg text-900 white-smoke p-2 px-4 ml-2 border-none border-round-lg"
                            onClick={() => {
                                setOpenCard(false);
                                setCardDetail({});
                                setErr({});
                            }}
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </Dialog>
            <div className="md:col-5">
                <div className="">
                    <DataTable className="" value={selectedItems?.length && selectedItems} tableStyle={{ minWidth: "20rem" }}>
                        <Column body={TypeTemplate} header="Type" className="bg-light-green font-bold" />
                        <Column
                            field="account_no"
                            body={AccountTemplate}
                            header="Account No."
                            className="bg-light-green font-bold"
                        />
                        <Column body={ActionTemplate} header="Action" className="bg-light-green font-bold" />
                    </DataTable>
                </div>
            </div>
        </>
    );
};

export default AddAndEditCard;
