import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import LayoutData from "./Layout";

export default function SideBar() {
    const location = useLocation();

    const activeLink = location?.pathname;

    const { menuItems } = LayoutData();

    const [isActiveSubMenu, setIsActiveSubMenu] = useState(false);

    const [activeSubMenu, setActiveSubMenu] = useState();

    const toggleSubMenu = (key) => {
        setIsActiveSubMenu((prev) => !prev);
        setActiveSubMenu("subMenu" + key);
    };

    return (
        <>
            <div className="shadow-1 overflow-scroll" style={{ height: "93vh" }}>
                <ul className="pt-3">
                    {menuItems.map((item, key) => (
                        <li
                            key={key}
                            className={
                                // item.link === activeLink
                                activeLink.includes(item.link)
                                    ? "p-3 side_menuitem text-dark active"
                                    : "p-3 side_menuitem text-dark "
                            }
                        >
                            <span className=" cursor-pointer" onClick={() => toggleSubMenu(key)}>
                                <Link
                                    to={item.link}
                                    className="no-underline side_menuitem text-dark open-sans flex align-items-center justify-content-between"
                                >
                                    <div>
                                        {item.icon? <img src={item.icon} width={item.iconWidth} height={item.iconHeight} alt="" /> : null}
                                        &nbsp;{item.name}
                                    </div>
                                    {item.subMenu ? (
                                        <span>
                                            <i
                                                className={
                                                    isActiveSubMenu && activeSubMenu === "subMenu" + key
                                                        ? "pi pi-chevron-up cursor-pointer"
                                                        : "pi pi-chevron-down cursor-pointer"
                                                }
                                            ></i>
                                        </span>
                                    ) : null}
                                </Link>
                            </span>
                            {item.subMenu ? (
                                <ul
                                    className="menu-show list-unstyled mt-3"
                                    key={key}
                                    style={{
                                        display: isActiveSubMenu && activeSubMenu === "subMenu" + key ? "block" : "none",
                                    }}
                                >
                                    {item.subMenu?.map((subMenu, index) => {
                                        return (
                                            <li
                                                key={index}
                                                className={
                                                    subMenu.link === activeLink
                                                        ? "p-3 side_menuitem text-dark active"
                                                        : "p-3 side_menuitem text-dark "
                                                }
                                            >
                                                <Link
                                                    to={subMenu.link}
                                                    className="no-underline side_menuitem text-dark open-sans flex align-items-center pt-2"
                                                >
                                                    {subMenu.name}
                                                </Link>
                                            </li>
                                        );
                                    })}
                                </ul>
                            ) : null}
                        </li>
                    ))}
                </ul>
            </div>
        </>
    );
}
