import types from "../types";
const initialState = {
  getPurchasePlan: [],
  viewPurchasePlan: {},
  addPurchasePlan: [],
};
const purchasePlanReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_PURCHASE_PLAN:
      return {
        ...state,
        getPurchasePlan: action.payload,
      };
    case types.ADD_PURCHASE_PLAN:
      return {
        ...state,
        addPurchasePlan: action.payload,
      };
    case types.GET_ONE_PURCHASE_PLAN:
      return {
        ...state,
        viewPurchasePlan: action.payload,
      };

    default:
      return { ...state };
  }
};

export default purchasePlanReducer;
