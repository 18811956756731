import types from "../types";
const initialState = {
    getPrivileges: {},
    getTeachers: [],
    viewTeacher: {},
    viewEvents: {},
    viewAttandance: [],
    viewStudent: [],
    getCategories: [],
    viewTeacherStudent: {},
    getTeacherAvailibitities: [],
    viewTeacherAvailability: {},
    viewTeacherSchools: [],
    getTeacherEvents: [],
    getCompensation: [],
    viewPayment: {},
    getTeacherDropdown: [],
};
const TeacherReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_PRIVELEGES:
            return {
                ...state,
                getPrivileges: action.payload,
            };
        case types.FETCH_TEACHERS:
            return {
                ...state,
                getTeachers: action.payload,
            };
        case types.VIEW_TEACHER:
            return {
                ...state,
                viewTeacher: action.payload,
            };
        case types.VIEW_TEACHER_EVENTS:
            return {
                ...state,
                viewEvents: action.payload,
            };
        case types.VIEW_TEACHER_ATTANDENCE:
            return {
                ...state,
                viewAttandance: action.payload,
            };
        case types.TEACHER_STUDENTS:
            return {
                ...state,
                viewStudent: action.payload,
            };
        case types.TEACHER_CATEGORIES:
            return {
                ...state,
                getCategories: action.payload,
            };
        case types.TEACHER_STUDENT_VIEW:
            return {
                ...state,
                viewTeacherStudent: action.payload,
            };
        case types.GET_TEACHER_AVAILIBILITIES:
            return { ...state, getTeacherAvailibitities: action.payload };
        case types.TEACHER_AVAILABILITY_VIEW:
            return { ...state, viewTeacherAvailability: action.payload };
        case types.VIEW_TEACHER_SCHOOLS:
            return { ...state, viewTeacherSchools: action.payload };
        case types.FETCH_TEACHER_EVENTS:
            return { ...state, getTeacherEvents: action.payload };
        case types.GET_COMPENSATION:
            return { ...state, getCompensation: action.payload };
        case types.COMPENSATION_PAYMENT_VIEW:
            return { ...state, viewPayment: action.payload };
        case types.FETCH_TEACHERS_DROPDOWN:
            return { ...state, getTeacherDropdown: action.payload };
        default:
            return { ...state };
    }
};

export default TeacherReducer;
