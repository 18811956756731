import React, { useState, useEffect, useRef } from "react";
import BrundCrumb from "../../assets/icons/brundcrumb.png";
import Logo from "../../assets/logo/logo.png";
import Notification from "../../assets/icons/notification.png";
import QuestionMark from "../../assets/icons/questionmark.png";
import { useHistory } from "react-router-dom";
import Signout from "../../assets/icons/signout.png";
import { logoutAction } from "../../redux/actions/loginAction";
import { useDispatch, useSelector } from "react-redux";
import { removeStateFn } from "../../utils/localStorage";
import { getProfileApi } from "../../redux/actions/loginAction";
import {
    ToggleNotificationAction,
    deleteSelectedNotificationAction,
    getNotificationAction,
} from "../../redux/actions/settingsAction";
import { OverlayPanel } from "primereact/overlaypanel";

export default function TopBar({ toggleSidebar }) {
    const [isActiveSubMenu, setIsActiveSubMenu] = useState(false);
    const dispatch = useDispatch();

    const [activeSubMenu, setActiveSubMenu] = useState();
    useEffect(() => {
        dispatch(getProfileApi());
    }, [dispatch]);
    const history = useHistory();
    const toggleSubMenu = () => {
        setIsActiveSubMenu((prev) => !prev);
        setActiveSubMenu("subMenu");
    };
    const handleLogout = () => {
        dispatch(logoutAction());
        removeStateFn("token");
        history.push("/");
    };

    const profile = useSelector((state) => state?.login?.getProfile);
    const NotificationClick = useRef(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        dispatch(getNotificationAction(setLoading, "unread"));
    }, [dispatch]);
    const notifications = useSelector((state) => state?.settings?.getNotifications);
    const [selectedRow, setSelectedRow] = useState([]);

    //

    return (
        <>
            <div className="bg-main px-4 p-2 flex justify-content-between border-round-bottom-2xl">
                <div className="flex align-items-center">
                    <button className="border-0 bg-transparent p-2">
                        <img src={BrundCrumb} onClick={() => toggleSidebar((prev) => !prev)} className="w-5" alt="" />
                    </button>
                    <div className="flex align-items-center">
                        <img src={Logo} className="w-3" alt="" />
                    </div>
                </div>
                <div className="flex relative">
                    <div className="flex align-items-center relative">
                        <i
                            className="pi pi-bell text-xl cursor-pointer"
                            onClick={(e) => NotificationClick?.current?.toggle(e)}
                        ></i>
                        {notifications?.filter((item) => item.is_read === 0)?.length > 0 ? (
                            <div
                                className="absolute    bg-red-600 border-circle text-white flex justify-content-center align-items-center  "
                                style={{ width: "8px", height: "8px", padding: "2px", marginLeft: "10px", marginTop: "-15px" }}
                            >
                                {/* <small style={{ fontSize: "8px" }}>
                                    {notifications?.filter((item) => item.is_read === 0)?.length}
                                </small> */}
                            </div>
                        ) : null}
                        <p className=" ml-2 open-sans font-normal mx-3"></p>
                    </div>
                    <OverlayPanel ref={NotificationClick} className="cursor-pointer w-400  hide-scrollbar marign-remove ">
                        <div>
                            <div className="bg-primary flex justify-content-between align-items-center p-3">
                                <h3 className="text-lg text-primary  m-0 text-white">Notification</h3>
                            </div>

                            <div className="">
                                <div className="notification-div">
                                    {notifications?.filter((item) => item.is_read === 0)?.length > 0 ? (
                                        notifications
                                            ?.filter((item) => item.is_read === 0)
                                            ?.map((item, i) => {
                                                return (
                                                    <>
                                                        <div
                                                            className={`flex align-items-center relative p-2 lg:px-4 border-top-1 border-white w-full ${
                                                                item.is_read === 0 ? "surface-100" : ""
                                                            } `}
                                                            onClick={(e) =>
                                                                dispatch(ToggleNotificationAction([item], setSelectedRow, 1))
                                                            }
                                                        >
                                                            <h4 className="text-sm text-900 p-0 my-2 font-normal flex flex-column">
                                                                {item?.message}
                                                            </h4>
                                                            <span className="text-lg text-right font-bold absolute cross-sign ">
                                                                <i
                                                                    className="pi pi-times"
                                                                    onClick={() =>
                                                                        dispatch(
                                                                            deleteSelectedNotificationAction(
                                                                                [item],
                                                                                setSelectedRow
                                                                            )
                                                                        )
                                                                    }
                                                                />
                                                            </span>
                                                        </div>
                                                    </>
                                                );
                                            })
                                    ) : (
                                        <div className=" align-items-center jusitfy-content-center p-5 w-full text-center">
                                            <small>No Notification</small>
                                        </div>
                                    )}
                                </div>
                                {notifications?.filter((item) => item.is_read === 0)?.length > 0 && (
                                    <div
                                        className="text-center mt-3"
                                        onClick={(e) => {
                                            NotificationClick?.current?.toggle(e);
                                            history.push("/settings");
                                        }}
                                    >
                                        View All Notifications
                                    </div>
                                )}
                            </div>
                        </div>
                    </OverlayPanel>
                    {/* <div className="flex align-items-center">
                        <img src={QuestionMark} alt="" className="w-3" />
                        <p className=" ml-2 open-sans font-normal m-0">Help</p>
                    </div> */}
                    <div className="relative cursor-pointer" onClick={() => toggleSubMenu()}>
                        <div className="flex align-items-center h-full">
                            <div className="avtar border bg-white p-2">
                                <p className="open-sans font-semibold">{profile?.name?.substring(0, 2)}</p>
                            </div>
                            <div className="ml-2" style={{ lineHeight: "20px" }}>
                                <p className="open-sans font-semibold m-0">{profile?.name}</p>
                                <small className="font-normal">{profile?.email}</small>
                            </div>
                            <span>
                                <i
                                    className={
                                        isActiveSubMenu ? "pi pi-chevron-up cursor-pointer" : "pi pi-chevron-down cursor-pointer"
                                    }
                                ></i>
                            </span>
                        </div>
                        <ul
                            className="absolute border-round-lg shadow-1 z-2 list-unstyled bg-white w-full p-3"
                            style={{
                                display: isActiveSubMenu && activeSubMenu === "subMenu" ? "block" : "none",
                            }}
                        >
                            <li>
                                <span>
                                    <div to="" className="text-sm text-900 inter font-semibold" onClick={handleLogout}>
                                        <img src={Signout} alt="" width={15} />
                                        &nbsp;Sign Out
                                    </div>
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div
                className="overlay-set absolute w-full h-full top-0 left-0 z-1"
                style={{
                    display: isActiveSubMenu && activeSubMenu === "subMenu" ? "block" : "none",
                }}
                onClick={() => toggleSubMenu()}
            ></div>
        </>
    );
}
