import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { loginAction } from "../../redux/actions/loginAction";
import { useHistory } from "react-router-dom";
var base64 = require("base-64");

const initialState = {
    email: "info@fundamentalmusic.org",
    password: "123456",
};

const Login = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const history = useHistory();
    const dispatch = useDispatch();
    const [adminData, setAdminData] = useState(initialState);
    const [err, setErr] = useState({});

    useEffect(() => {
        const rememberMe = localStorage.getItem("rememberMe") === "true";
        const email = rememberMe ? localStorage.getItem("email") : "info@fundamentalmusic.org";
        const password = rememberMe ? localStorage.getItem("Detail") : "123456";
        if (localStorage.getItem("rememberMe") === "false") {
            localStorage.removeItem("email");
            localStorage.removeItem("Detail");
            setAdminData({
                ["email"]: "",
                ["password"]: "",
                ["rememberMe"]: false,
            });
        }

        setAdminData({
            ["email"]: email,
            ["password"]: 123456,
            rememberMe,
        });
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        const form = validateForm();
        if (form) {
            dispatch(loginAction(adminData, history));
        }
    };

    const validateForm = () => {
        let errors = {};
        if (!adminData?.email) {
            errors.email = "Email field is required ";
        }
        if (!adminData?.password) {
            errors.password = "Password  field is required ";
        }

        setErr(errors);
        if (Object.keys(errors).length === 0) {
            return true;
        } else {
            return false;
        }
    };

    const handleChange = (e) => {
        const { name, value, checked } = e.target;
        if (name === "rememberMe") {
            setAdminData({ ...adminData, [name]: checked });
        } else {
            setAdminData({ ...adminData, [name]: value });
            setErr({ ...err, [name]: "" });
        }
    };

    return {
        adminData,
        handleSubmit,
        handleChange,
        err,
    };
};

export default Login;
