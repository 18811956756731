import React, { useState, useEffect } from "react";
import Logo from "../../assets/logo/logo.png";
import CustomCard from "../Fields/CustomCard";
import { Divider } from "primereact/divider";
import DropDownComponent from "../Fields/CustomDropdown";
import { CardOptions, generateMonthOptions, generateYearOptions } from "../../utils/constants";
import { usePaymentInputs } from "react-payment-inputs";
import images from "react-payment-inputs/images";
import InputComponent from "../Fields/InputField";
import { Country, State } from "country-state-city";
import ButtonComponent from "../Fields/Button/CustomButton";

const InvoicePayments = () => {
    const { meta, getCardNumberProps, getCardImageProps, getCVCProps } = usePaymentInputs();
    const [countries] = useState(Country.getAllCountries());
    const [countryStates, setCountryStates] = useState([]);
    const [cardDetail, setCardDetail] = useState({
        card_holder_name: "",
        card_type: "",
        cardNumber: "",
        expiry_month: "",
        expiry_year: "",
        card_security_code: "",
        country: [],
        state: [],
        city: "",
        address_line_1: "",
        address_line_2: "",
        zip_code: "",
        auto_pay: false,
    });
    const [err, setErr] = useState({});
    useEffect(() => {
        getUpdatedStates();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cardDetail?.country]);

    const getUpdatedStates = () => {
        let res = State.getStatesOfCountry(cardDetail?.country);
        setCountryStates(res);
    };
    const handleChange1 = (e) => {
        const { name } = e.target;
        setCardDetail({ ...cardDetail, [name]: e.value });
        setErr({ ...err, card_type: "", expiryDate: "" });
        // setErr({ ...err, ["expiryDate"]: "" });
    };
    const handleChange = (e) => {
        const { name, value } = e.target;
        setCardDetail({ ...cardDetail, [name]: value });
    };
    const month = cardDetail?.expiry_month;
    const year = cardDetail?.expiry_year;
    const isExpirationDateValid = validateExpirationDate({
        month,
        year,
    });

    function validateExpirationDate() {
        const month = parseInt(cardDetail?.expiry_month);
        const year = parseInt(cardDetail?.expiry_year);
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();

        const currentMonth = currentDate.getMonth() + 1; // Add 1 because getMonth() returns 0-based index
        return year > currentYear || (year === currentYear && month >= currentMonth);
    }

    const validateForm = () => {
        let errors = {};
        if (!cardDetail?.card_type) {
            errors.card_type = "Card Type is required";
        }
        if (meta.erroredInputs.cardNumber) {
            errors.card_number = meta.erroredInputs.cardNumber;
        }
        if (!cardDetail?.expiry_month) {
            errors.expiryDate = "Expiry Month is required";
        } else if (!cardDetail?.expiry_year) {
            errors.expiryDate = "Expiry Year is required";
        } else if (!isExpirationDateValid) {
            errors.expiryDate = "The expiration date is invalid. Please enter a valid expiration date.";
        }
        if (meta.erroredInputs.cvc) {
            errors.card_security_code = meta.erroredInputs.cvc;
        }

        setErr(errors);
        if (Object.keys(errors).length === 0) {
            return true;
        } else {
            return false;
        }
    };

    const handlePayment = () => {
        const form = validateForm();
        if (form) {
        }
    };

    return (
        <div className="lg:p-5 md:p-4">
            <h4 className="font-semibold mb-3">Payment</h4>
            <div className="grid">
                <div className="lg:col-7">
                    <CustomCard>
                        <div className="flex ">
                            <div className="logo-box" style={{ width: "90px", height: "90px" }}>
                                <img src={Logo} alt="" className="w-full h-full" />
                            </div>
                            <div className="ml-3 w-5">
                                <p className="text-sm font-semibold">Fundamental Music Instructions</p>
                                <p>70 West Shore St. Keansburg NJ 07734</p>
                            </div>
                        </div>
                        <Divider />
                        <p className="text-sm font-semibold">
                            Account : <span className="font-normal">(AKNM) Cornell, Riley; Cornell, Alexis</span>
                        </p>
                    </CustomCard>
                    <p className="text-lg font-bold mb-3">Payment Method</p>
                    <CustomCard>
                        <h6 className="font-bold">New Credit Card</h6>
                        <div className="grid">
                            <DropDownComponent
                                options={CardOptions}
                                label=" Card Type"
                                size="mb-3 col-6"
                                placeholder="Select Card Type"
                                optionLabel="name"
                                required
                                name="card_type"
                                value={cardDetail?.card_type}
                                onChange={handleChange1}
                                // errorMessage={err?.card_type}
                            />
                            <div className="col-6 md:col-6 mb-3">
                                <div className="relative">
                                    <svg {...getCardImageProps({ images })} className="absolute card-svg" />
                                    <input
                                        className="w-full p-2 border-round-lg border-1 border-400"
                                        name="cardNumber"
                                        {...getCardNumberProps({ onChange: handleChange })}
                                        value={cardDetail?.cardNumber}
                                    />
                                </div>
                                {/* <div className="text-danger text-sm">{err?.card_number}</div> */}
                            </div>
                            <InputComponent
                                label=" Card Holder Name"
                                size="mb-3 col-12"
                                placeholder="Enter Name"
                                name="card_holder_name"
                                value={cardDetail?.card_holder_name}
                                onChange={handleChange}
                            />
                            <div className="grid flex mb-2 align-items-center">
                                <div className="md:col-12">
                                    <p className=" font-bold">Expiry Date (MM/YY)</p>
                                </div>
                                <DropDownComponent
                                    size="md:col-4"
                                    placeholder="Select Month"
                                    required
                                    optionLabel="name"
                                    options={generateMonthOptions()}
                                    name="expiry_month"
                                    value={cardDetail?.expiry_month}
                                    onChange={handleChange1}
                                />
                                <DropDownComponent
                                    size="md:col-4"
                                    placeholder="Select Year"
                                    required
                                    options={generateYearOptions()}
                                    optionLabel="name"
                                    name="expiry_year"
                                    value={cardDetail?.expiry_year}
                                    onChange={handleChange1}
                                />
                                <small className="text-danger open-sans text-sm mb-2">
                                    {/* {err?.expiryDate && <p>{err?.expiryDate}</p>} */}
                                </small>
                                <div className="col-4 md:col-4 mb-2">
                                    <div className="relative">
                                        <input
                                            className="w-full p-2 border-round-lg border-1 border-400"
                                            {...getCVCProps({ onChange: handleChange })}
                                            name="card_security_code"
                                            value={cardDetail?.card_security_code}
                                            placeholder="CVV"
                                        />
                                    </div>
                                    {/* <div className="text-danger text-sm">{err?.card_security_code}</div> */}
                                </div>
                                <DropDownComponent
                                    label="Country"
                                    size="mb-3 col-6"
                                    name="country"
                                    optionLabel="name"
                                    options={
                                        countries?.length &&
                                        countries?.map((country, i) => {
                                            return { name: country.name, value: country.isoCode };
                                        })
                                    }
                                    value={cardDetail?.country}
                                    onChange={handleChange1}
                                />
                                <DropDownComponent
                                    label="State/Province"
                                    size="mb-3 col-6"
                                    name="state"
                                    optionLabel="name"
                                    options={countryStates?.map((state, i) => {
                                        return { name: state.name, value: state.isoCode };
                                    })}
                                    value={cardDetail?.state}
                                    onChange={handleChange1}
                                />
                                <InputComponent
                                    label="City"
                                    size="mb-3 col-6"
                                    name="city"
                                    value={cardDetail?.city}
                                    onChange={handleChange}
                                />
                                <InputComponent
                                    label="Zip/Postal Code"
                                    size="mb-3 col-6"
                                    name="zip_code"
                                    value={cardDetail?.zip_code}
                                    onChange={handleChange}
                                    keyfilter="int"
                                />
                                <InputComponent
                                    label="Address Line 1"
                                    size="mb-3 col-12"
                                    name="address_line_1"
                                    value={cardDetail?.address_line_1}
                                    onChange={handleChange}
                                />
                                <InputComponent
                                    label="Address Line 2"
                                    size="mb-3 col-12"
                                    name="address_line_2"
                                    value={cardDetail?.address_line_2}
                                    onChange={handleChange}
                                />
                                <Divider />

                                <ButtonComponent label="Pay Now" className="ml-auto" onClick={handlePayment} />
                            </div>
                        </div>
                    </CustomCard>
                </div>
                <div className="lg:col-5">
                    <CustomCard>
                        <h5 className="font-semibold m-0">Payment Summary</h5>
                        <Divider />
                        <div className="flex justify-content-between">
                            <p>Subtotal</p>
                            <p>$350.00</p>
                        </div>
                        <Divider />
                        <div className="flex justify-content-between font-semibold">
                            <p>Total</p>
                            <p>$350.00</p>
                        </div>
                    </CustomCard>
                </div>
            </div>
        </div>
    );
};

export default InvoicePayments;
